/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

$sp-raw-poll-result-tag-name-width: 15rem;
$sp-raw-poll-result-tag-value-width: 15rem;
$sp-raw-poll-result-tag-margin: .0625rem;

.sp-raw-poll-result-view-dialog {

	.sp-dialog-column {
		@include flexbox-item(row, 1, 1);
	}

	.sp-raw-poll-result-tag {
		@include flexbox-item(column, 1, 1, 1.125rem);
		@include flexbox-container(row);

		> .sp-raw-poll-result-tag-part {
			@include flexbox-item(row, 1, 1);
			margin: $sp-raw-poll-result-tag-margin;

			overflow: hidden;
			// ellipsis truncating
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		> .sp-raw-poll-result-tag-name {
			@include font-t(7);
			color: $color-text;
			width: $sp-raw-poll-result-tag-name-width;
		}

		> .sp-raw-poll-result-tag-value {
			@include font-t(9);
			color: $color-text;
			width: $sp-raw-poll-result-tag-value-width;
		}
	}

	.sp-raw-poll-result-data {
		@include flexbox-item(column, 1, 1);
		overflow-x: auto;
		overflow-y: hidden;

		> .sp-raw-poll-result-data-text {
			@include font-t(16);
			color: $color-text;
		}
	}
}
