.sp-content-body > .sp-about {
	margin-left: 2em;
	margin-top: 2em;
}

.sp-about {
	@include flexbox-container(row, wrap, center, flex-start, flex-start);

	> .sp-panel {
		margin-bottom: 2em;
		margin-right: 2em;

		> .sp-panel-body {
			@include flexbox-container(column, nowrap);
			height: 10em;
			width: 20em;

			> .sp-about-rows {
				@include flexbox-item(column, 1, 1);
				@include flexbox-container(column, nowrap);
				margin-top: 2em;

				> .sp-dashlist-row {
					@include flexbox-item(column, 0, 1, 1em, 1em);
					@include flexbox-container(row, nowrap, stretch, center);
					margin-top: .2em;

					.sp-dashlist-cell {
						height: 1.1em;
					}

					.sp-dashlist-cell:first-child {
						@include flexbox-item(row, 0, 0, 9em, 9em);
						margin-left: 1em;
					}

					.sp-dashlist-cell:last-child {
						@include flexbox-item(row, 1, 1);
						margin-right: 1em;
					}

					.sp-dashlist-title {
						@include font-t(7);
						color: $color-text;
					}

					.sp-dashlist-value {
						@include font-t(9);
						color: $color-text;
					}

					.sp-dashlist-cell.sp-error {
						color: $color-error;
					}

					.sp-dashlist-cell.sp-warn {
						color: $color-warn;
					}
				}

				> .sp-dashlist-row:first-child {
					margin-top: .5em;
				}
			}

			> .sp-about-copyright {
				@include flexbox-item(column, 0, 0, 2em, 2em);
				@include font-t(7);
				color: $color-text;
				text-align: center;
			}

		}
	}
}
