/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-dialog-sections {
	@include flexbox-container(column, nowrap, flex-start, stretch, flex-start);
	background-color: $background-color-content;
	overflow: auto;
	padding-top: 1em;

	> .sp-accordion {
		background-color: $background-color-content;

		> .sp-accordion-item-heading {
			background-color: $background-color-overall;
			border-bottom: 1px solid $inner-border-color;
			border-top: 1px solid $inner-border-color;
			margin-bottom: 1em;
			margin-left: 1em;
			margin-right: 1em;

			> .sp-accordion-item-heading-content {
				@include flexbox-container(row, nowrap, flex-start, center);

				> .sp-dialog-section-title {
					@include flexbox-container(row, nowrap, flex-start, center);
					@include flexbox-item(row, 1, 1, auto, auto, auto);
					@include font-t(8);

					> div {
						@include flexbox-item(row, 0, 1, auto, auto, auto);
						color: $color-text;
					}

					> .sp-dialog-section-title-error {
						color: $color-error;
						margin-left: .5em;
					}

					> .sp-dialog-section-error-no-title {
						margin-left: 0;
					}
				}
			}
		}

		> .sp-accordion-item-heading-expanded {
			margin-bottom: 0;
		}
	}
}

.sp-dialog-section {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column, nowrap, flex-start, stretch, flex-start);
	background-color: $background-color-content;
	overflow: hidden;
	padding-bottom: 1em;

	> .sp-dialog-columns > .sp-dialog-column {
		margin-left: 1em;
		margin-right: 1em;
	}
}
