/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-date-time-range-input {
	@include flexbox-container(row);

	> .sp-date-time-range-input-begin {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(column);

		> .sp-date-time-input {
			@include flexbox-item(column, 1, 0);
		}
	}

	> .sp-date-time-range-input-end {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(column);
		margin-left: .5em;

		> .sp-date-time-input {
			@include flexbox-item(column, 1, 0);
		}
	}
}
