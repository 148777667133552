/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-input-label {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
	padding: .5em 0;
	width: 100%;

	> .sp-input-label-labels {
		padding-bottom: .5em;

		> .sp-input-label-text {
			@include font-t(7);
			color: $input-label-color;
		}

		> .sp-input-label-text:nth-child(1n+2) {
			padding-left: 1em;
		}
	}

	> .sp-input-label-inputs {
		@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
		position: relative;

		> * {
			@include flexbox-item(column, 0, 0);
		}
	}

	> .sp-disabled > .sp-input-label-labels > .sp-input-label-text {
		color: $input-label-color-disabled;
	}
}

.sp-input-label-top {
	> .sp-input-label-labels {
		@include flexbox-item(column, 0, 0);
	}

	> .sp-input-label-inputs {
		@include flexbox-item(column, 1, 1);
	}
}

.sp-input-label-left {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(row, wrap, flex-start, center, flex-start);
	width: 100%;

	> .sp-input-label-labels {
		@include flexbox-item(row, 0, 0, 180px, 180px, center);
		padding-bottom: 0;
	}

	> .sp-input-label-inputs {
		@include flexbox-item(row, 1, 1, 350px, 350px);
	}
}

.sp-input-label-right {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(row, wrap, flex-start, center, flex-start);
	width: 100%;

	> .sp-input-label-labels {
		@include flexbox-item(row, 0, 0, 180px, 180px, center);
		order: 1;
		padding-bottom: 0;
	}

	> .sp-input-label-inputs {
		@include flexbox-item(row, 1, 1, 350px, 350px);
		order: 0;
	}
}
