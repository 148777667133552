/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-old-combo-box-labels {
	display: block;
}

.sp-old-combo-box-spacer {
	display: block;
	height: .25em;
}

.sp-old-combo-box-controls {
	display: block;
}

.sp-old-combo-boxinput-input {
	width: 100%;
}
