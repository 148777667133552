/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-gridcelldefault {
	@include flexbox-container(row, nowrap, flex-start, center, center);
	height: 100%;
	white-space: nowrap;
	width: 100%;
}

.sp-gridcelldefault.sp-gridcelldefault-alignright {
	@include flexbox-container(row, nowrap, flex-end, center, center);
}

.sp-gridcelldefault.sp-gridcelldefault-aligncenter {
	@include flexbox-container(row, nowrap, center, center, center);
}

.sp-gridcelldefault-value {
	@include flexbox-item(row, 0, 1);
	line-height: 1.5em;
	overflow: hidden;
	// fix right-aligned italic text cut off
	// https://stackoverflow.com/questions/21460772/right-aligned-italic-text-in-textbox-cut-off
	padding-right: 3px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
