/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$navdisplay-item-size: 8em;
$navdisplay-item-margin: .125em;

.sp-content-body > .sp-navdisplay {
	margin: 2em;
}

.sp-navdisplay {
	@include flexbox-container(row, wrap, flex-start, flex-start, flex-start);

	> .sp-navdisplay-noitems {
		@include flexbox-item(row, 0, 0);
		@include font-t(5);
		color: $color-text;
		margin-left: 2em;
		margin-top: 2em;
	}

	> .sp-navdisplay-item {
		@include box-shadow($default-box-shadow);
		@include flexbox-container(column, nowrap, space-around, stretch, stretch);
		@include flexbox-item(row, 0, 0);
		background-color: $background-color-content;
		cursor: pointer;
		height: $navdisplay-item-size; // fixed width/height to make all dashboard blocks equal
		margin-bottom: 1em;
		margin-right: 1em;
		overflow: hidden;
		width: $navdisplay-item-size;


		> .sp-navdisplay-icon-wrapper {
			@include flexbox-item(column, 0, 0, 5em, 5em);
			@include flexbox-container(column, nowrap, flex-end, center, center);
			text-align: center;

			> .sp-navdisplay-icon {
				@include flexbox-item(column, 0, 0);
				font-size: 4em;
			}
		}

		> .sp-navdisplay-text-wrapper {
			@include flexbox-item(column, 1, 1);
			@include flexbox-container(column, nowrap, center, center, center);
			margin: 2px;
			text-align: center;

			> .sp-navdisplay-text {
				@include flexbox-item(column, 0, 0);
				@include font-t(5);
				color: $color-title-text;
				// make text wrap on IE
				white-space: normal;
				width: $navdisplay-item-size - 2 * $navdisplay-item-margin;
				word-wrap: break-word;
			}
		}
	}
}
