/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-dashboard-tag {
	@include flexbox-container(column, nowrap, space-between, center);
	background-color: $background-color-overall;
	color: $color-text;
	padding: .5em;

	&.sp-disabled {
		color: $color-disabled-text;
	}

	&.sp-error {
		color: $color-error-text;
	}

	.sp-dashboard-tag-icon {
		font-size: 4em;
	}

	.sp-dashboard-tag-name {
		@include font-t(12);
	}

	.sp-dashboard-tag-unit {
		@include font-t(10);
		color: $color-disabled-text;
	}

	.sp-dashboard-tag-value {
		@include font-t(1);
	}
}

.sp-dashboard-tag.sp-dashboard-tag-small {

	height: 4.5em;

	.sp-dashboard-tag-icon {
		font-size: 2em;
	}

	.sp-dashboard-tag-name {
		@include font-t(7);
	}

	.sp-dashboard-tag-unit {
		@include font-t(10);
		color: $color-disabled-text;
	}

	.sp-dashboard-tag-value {
		@include font-t(6);
		font-size: 1.125em;
	}
}

