/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

/**
 * Can be used to create an arrow using a div element. The default arrow color can be overwritten by resetting the border color.
 * The additional arrow-outside class can be used to draw the arrow on the outside of its container when positioned absolutely on
 * one of the container's edges.
 */

$arrow-small-size: .5em;
$arrow-normal-size: .625em;

.arrow-up {
	border-bottom: $arrow-normal-size solid $color-grey-30;
	border-left: $arrow-normal-size solid transparent !important;
	border-right: $arrow-normal-size solid transparent !important;
	height: 0;
	margin-left: -$arrow-normal-size;
	margin-right: -$arrow-normal-size;
	width: 0;

	&.arrow-outside {
		margin-top: -$arrow-normal-size;
	}
}

.arrow-down {
	border-left: $arrow-normal-size solid transparent !important;
	border-right: $arrow-normal-size solid transparent !important;
	border-top: $arrow-normal-size solid $color-grey-30;
	height: 0;
	margin-left: -$arrow-normal-size;
	margin-right: -$arrow-normal-size;
	width: 0;

	&.arrow-outside {
		margin-bottom: -$arrow-normal-size;
	}
}

.arrow-right {
	border-bottom: $arrow-normal-size solid transparent !important;
	border-left: $arrow-normal-size solid $color-grey-30;
	border-top: $arrow-normal-size solid transparent !important;
	height: 0;
	margin-bottom: -$arrow-normal-size;
	margin-top: -$arrow-normal-size;
	width: 0;

	&.arrow-outside {
		margin-right: -$arrow-normal-size;
	}
}

.arrow-left {
	border-bottom: $arrow-normal-size solid transparent !important;
	border-right: $arrow-normal-size solid $color-grey-30;
	border-top: $arrow-normal-size solid transparent !important;
	height: 0;
	margin-bottom: -$arrow-normal-size;
	margin-top: -$arrow-normal-size;
	width: 0;

	&.arrow-outside {
		margin-left: -$arrow-normal-size;
	}
}

.arrow-up-small {
	border-bottom: $arrow-small-size solid $color-grey-30;
	border-left: $arrow-small-size solid transparent !important;
	border-right: $arrow-small-size solid transparent !important;
	height: 0;
	margin-left: -$arrow-small-size;
	margin-right: -$arrow-small-size;
	width: 0;

	&.arrow-outside {
		margin-top: -$arrow-small-size;
	}
}

.arrow-down-small {
	border-left: $arrow-small-size solid transparent !important;
	border-right: $arrow-small-size solid transparent !important;
	border-top: $arrow-small-size solid $color-grey-30;
	height: 0;
	margin-left: -$arrow-small-size;
	margin-right: -$arrow-small-size;
	width: 0;

	&.arrow-outside {
		margin-bottom: -$arrow-small-size;
	}
}

.arrow-right-small {
	border-bottom: $arrow-small-size solid transparent !important;
	border-left: $arrow-small-size solid $color-grey-30;
	border-top: $arrow-small-size solid transparent !important;
	height: 0;
	margin-bottom: -$arrow-small-size;
	margin-top: -$arrow-small-size;
	width: 0;

	&.arrow-outside {
		margin-right: -$arrow-small-size;
	}
}

.arrow-left-small {
	border-bottom: $arrow-small-size solid transparent !important;
	border-right: $arrow-small-size solid $color-grey-30;
	border-top: $arrow-small-size solid transparent !important;
	height: 0;
	margin-bottom: -$arrow-small-size;
	margin-top: -$arrow-small-size;
	width: 0;

	&.arrow-outside {
		margin-left: -$arrow-small-size;
	}
}
