/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-logindialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;

	> form {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);

		> .sp-exlent-logo {
			@include flexbox-item(column, 1, 1, 2.5em, 2.5em);
			background-image: url("/core/static/images/eXLent_RGB.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			margin-bottom: 1em;
			margin-top: 2em;
		}

		> .sp-exlent-logo-subtitle {
			@include flexbox-item(column, 0, 0);
			@include flexbox-container(row, nowrap, center, center, center);
			margin-bottom: 3em;

			> .sp-exlent-logo-subtitle-text {
				@include flexbox-item(row, 0, 0);
				@include font-t(14);
				color: $color-grey-50;
			}
		}

		> .sp-old-input-label {
			@include flexbox-item(column, 0, 0);
			min-width: 14em;
		}

		> .sp-forgot-password-link {
			@include flexbox-item(column, 0, 0, auto, auto, flex-end);
			margin-bottom: 1em;
		}
	}
}

.sp-logindialog-general-errors {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap, space-around, stretch, flex-start);
	margin-bottom: 1em;
}

.sp-logindialog-general-error {
	@include flexbox-item(column, 0, 0);
	margin-bottom: .25em;
}
