/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

@media screen {
	.sp-splitter {
		height: 100%;
		overflow: hidden;
		width: 100%;
	}
}

.sp-splitter-vertical {
	@include flexbox-container(row, nowrap, space-between, stretch);

	> .sp-splitter-bar {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(column, nowrap, space-between, stretch);

		&:hover {
			cursor: w-resize;
		}
	}

	> .sp-splitter-pane:first-child {
		@include flexbox-item(row, 0, 0, auto, 100%);
	}

	> .sp-splitter-pane:last-child {
		@include flexbox-item(row, 1, 1, auto, 100%);
	}

	> .sp-splitter-pane {
		@include flexbox-container(column, nowrap, space-between, stretch);
		margin: 0;
		padding: 0;

		> .sp-splitter {
			@include flexbox-item(column, 1, 1, auto, 100%);
		}
	}

	&.sp-splitter-dragging {
		cursor: w-resize;

		> .sp-splitter-bar {
			background-color: $splitter-dragging-color;
		}
	}
}

.sp-splitter-horizontal {
	@include flexbox-container(column, nowrap, space-between, stretch);
	position: absolute; // Fix for Safari: https://bugs.webkit.org/show_bug.cgi?id=26559

	> .sp-splitter-bar {
		@include flexbox-item(column, 0, 0);

		&:hover {
			cursor: n-resize;
		}
	}

	> .sp-splitter-pane:first-child {
		@include flexbox-item(column, 0, 0);
	}

	> .sp-splitter-pane:last-child {
		@include flexbox-item(column, 0, 0);
	}

	> .sp-splitter-pane {
		@include flexbox-container(column, nowrap, space-between, stretch);
		margin: 0;
		padding: 0;

		> .sp-splitter {
			@include flexbox-item(column, 1, 1, auto, 100%);
		}
	}

	&.sp-splitter-dragging {
		cursor: n-resize;

		> .sp-splitter-bar {
			background-color: $splitter-dragging-color;
		}
	}
}

.sp-splitter-bar {
	background-color: $splitter-color;
	border-color: $splitter-border-color;
	border-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: $z-index-1;

	&:hover {
		background-color: $splitter-hover-color;
	}

	&.sp-splitter-bar-disabled:hover {
		cursor: normal;
	}
}

.sp-splitter-horizontal > .sp-splitter-bar > .sp-splitter-bar-selectable {
	position: relative;
	width: 100%;
}

.sp-splitter-vertical > .sp-splitter-bar > .sp-splitter-bar-selectable {
	position: absolute;
}

.sp-splitter > .sp-splitter-pane {
	overflow: hidden;
}

@media print {
	.sp-splitter > .sp-splitter-bar {
		display: none;
	}
}
