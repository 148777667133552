/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-date-picker {
	@include flexbox-container(row);
	background-color: $background-color-content;

	> .sp-calendar {
		@include flexbox-item(row, 1, 1);
	}
}
