/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-alarms-grid-panel {
	@include flexbox-container(column, nowrap, flex-start, stretch);

	> .sp-panel {
		@include flexbox-item(column, 1, 1);

		> .sp-panel-body {
			> .sp-grid-filter-bar {
				@include flexbox-item(column, 0, 1);
			}

			> .sp-alarms-grid-panel-grid {
				@include flexbox-container(row, nowrap, stretch, stretch);
				@include flexbox-item(column, 1, 1);

				> .sp-alarms-grid-bell {
					@include flexbox-item(row, 0, 0, 6em, 6em);
					@include flexbox-container(column, nowrap, center, center);
					background-color: $background-color-content;
					border-right: 2px solid $inner-border-color;

					>.sp-icon {
						@include flexbox-item(column, 0, 0);
						font-size: 4em;
					}

					> .sp-alarms-grid-alarm-count {
						@include flexbox-item(column, 0, 0);
						@include font-t(3);
					}

					.sp-disabled {
						color: $color-disabled-text;
					}

					.sp-error {
						color: $color-error-text;
					}
				}

				> .sp-virtualgrid-wrapper {
					@include flexbox-item(row, 1, 1);
				}
			}
		}
	}
}

@media screen {
	.sp-alarms-grid-panel > .sp-panel > .sp-panel-body > .sp-alarms-grid-panel-grid > .sp-virtualgrid-wrapper {
		@include scrollable-container;

		> .sp-virtualgrid {
			@include scrollable-item;
		}
	}
}
