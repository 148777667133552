/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-toggle-grid-columns-dialog {
	@include flexbox-item(column, 1, 1, 100%, 100%);
	@include flexbox-container(column);
	padding: 1em;

	> .sp-toggle-grid-columns-dialog-form {
		@include flexbox-item(column, 1, 1, 100%, 100%);
		@include flexbox-container(column);

		> .sp-check-box-input {
			@include flexbox-item(column, 0, 0, 1.5em, 1.5em);
		}
	}
}
