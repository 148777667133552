/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-overlay {
	outline: 0;
	pointer-events: none;
	position: absolute;
	z-index: $z-index-overlay;
}
