/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-button {
	@include flexbox-container(row, wrap);
	box-sizing: border-box;
	cursor: pointer;

	> .sp-button-icon {
		@include flexbox-item(row, 0, 0, 2em, 2em, auto);
		@include flexbox-container(column, nowrap, center, center);
		padding-left: .5em;

		> .sp-icon {
			font-size: 1.5em;
		}
	}

	> .sp-button-text {
		@include flexbox-item(row, 1, 0, auto, auto, auto);
		@include flexbox-container(column, nowrap, center);
		padding-right: 1em;

		> span {
			@include font-t(7);
		}
	}

	&:focus {
		outline: 0;
	}
}

.sp-button.sp-button-icon-only {
	> .sp-button-icon {
		padding: 0;
	}
}

.sp-button.sp-button-text-only {
	> .sp-button-text {
		padding: 0 1em;
	}
}

.sp-button.sp-disabled {
	cursor: default;
}

.sp-button.sp-button-centered {
	@include flexbox-container(row, wrap, center);

	> .sp-button-icon {
		padding: 0;
	}

	> .sp-button-text {
		@include flexbox-item(row, 0, 0, auto, auto, auto);
		padding: 0;
	}
}

// Dark button style
.sp-button-dark {
	background: $button-dark-background-color;
	color: $button-dark-foreground-color;
	padding: 3px 0;

	&:focus {
		border-bottom: 3px solid $button-dark-border-color-focus;
		padding-bottom: 0;
	}

	&:hover {
		background: $button-dark-background-color-hover;
		color: $button-dark-foreground-color-hover;

		&:focus {
			border-bottom: 3px solid $button-dark-border-color-hover-focus;
		}
	}

	&.sp-active {
		background: $button-dark-background-color-active;
		color: $button-dark-foreground-color-active;

		&:focus {
			border-bottom: 3px solid $button-dark-border-color-active-focus;
		}
	}

	&.sp-disabled {
		background: $button-dark-background-color-disabled;
		color: $button-dark-foreground-color-disabled;
	}
}

// Light button style
.sp-button-light {
	background: $button-light-background-color;
	color: $button-light-foreground-color;
	padding: 3px 0;

	&:focus {
		border-bottom: 3px solid $button-light-border-color-focus;
		padding-bottom: 0;
	}

	&:hover {
		background: $button-light-background-color-hover;
		color: $button-light-foreground-color-hover;

		&:focus {
			border-bottom: 3px solid $button-light-border-color-hover-focus;
		}
	}

	&.sp-active {
		background: $button-light-background-color-active;
		color: $button-light-foreground-color-active;

		&:focus {
			border-bottom: 3px solid $button-light-border-color-active-focus;
		}
	}

	&.sp-disabled {
		background: $button-light-background-color-disabled;
		color: $button-light-foreground-color-disabled;
	}
}

// Red button style
.sp-button-red {
	background: $button-red-background-color;
	color: $button-red-foreground-color;
	padding: 3px 0;

	&:focus {
		border-bottom: 3px solid $button-red-border-color-focus;
		padding-bottom: 0;
	}

	&:hover {
		background: $button-red-background-color-hover;
		color: $button-red-foreground-color-hover;

		&:focus {
			border-bottom: 3px solid $button-red-border-color-hover-focus;
		}
	}

	&.sp-disabled {
		background: $button-red-background-color-disabled;
		color: $button-red-foreground-color-disabled;
	}
}

// Transparent button style (text+icon only)
.sp-button-transparent {
	color: $button-light-foreground-color;
	padding: 3px 0;

	&:focus {
		border-bottom: 3px solid $button-light-border-color-focus;
		padding-bottom: 0;
	}

	&:hover {
		color: $button-light-foreground-color-hover;

		&:focus {
			border-bottom: 3px solid $button-light-border-color-hover-focus;
		}
	}

	&.sp-active {
		color: $button-light-foreground-color-active;

		&:focus {
			border-bottom: 3px solid $button-light-border-color-active-focus;
		}
	}

	&.sp-disabled {
		color: $color-grey-50;
	}
}

// buttons with accent colors
@for $i from 1 through length($accent-colors) {
	$accent-color: nth($accent-colors, $i);
	$accent-color-dark: nth($accent-colors-dark, $i);

	.sp-button.sp-accent#{$i} {
		background-color: $accent-color;
	}

	.sp-button.sp-accent#{$i}:focus,
	.sp-button.sp-accent#{$i}:hover {
		background-color: $accent-color-dark;
	}

	.sp-button.sp-accent#{$i}.sp-disabled {
		background-color: $button-dark-foreground-color-disabled;
	}

	.sp-button.sp-accent#{$i}.sp-disabled:focus,
	.sp-button.sp-accent#{$i}.sp-disabled:hover {
		background-color: $button-dark-foreground-color-disabled;
	}
}
