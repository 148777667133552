/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-input {
	@include border-radius($input-border-radius);
	@include font-t(7);
	background-color: $input-background-color;
	border: $input-border-width solid $color-grey-50;
	box-sizing: border-box;
	color: $input-color;
	display: block;
	height: 26px;
	margin: 0;
	outline: 0;
	padding: $input-padding;
	width: 100%;

	// Add this class to an .sp-input to add a prefix element before the input
	&.sp-input-with-prefix {
		@include border-radius(0 $input-border-radius $input-border-radius 0);
		border-left: 0;
		padding-left: 0;
		// width: calc(100% - 26px);
	}

	// Add this class to an .sp-input to add a suffix element after the input
	&.sp-input-with-suffix {
		@include border-radius($input-border-radius 0 0 $input-border-radius);
		border-right: 0;
		padding-right: 0;
		// width: calc(100% - 26px);
	}

	&.sp-input-with-prefix.sp-input-with-suffix {
		@include border-radius(0);
		// width: calc(100% - 2 * 26px);
	}

	&:focus,
	&.sp-input-with-prefix:focus + .sp-input-prefix,
	&.sp-input-with-suffix:focus + .sp-input-suffix,
	&.sp-active,
	&.sp-input-with-prefix.sp-active + .sp-input-prefix,
	&.sp-input-with-suffix.sp-active + .sp-input-suffix {
		border-bottom-width: $input-border-focus-width;
		padding-top: $input-border-focus-width - $input-border-width;
	}

	&.sp-input-diff,
	&.sp-input-diff.sp-input-with-prefix + .sp-input-prefix,
	&.sp-input-diff.sp-input-with-suffix + .sp-input-suffix {
		border-color: $input-border-color-diff;
	}

	&.sp-input-changed,
	&.sp-input-changed.sp-input-with-prefix + .sp-input-prefix,
	&.sp-input-changed.sp-input-with-suffix + .sp-input-suffix {
		background-color: $input-background-color-changed;
	}

	&:disabled,
	&.sp-input-with-prefix:disabled + .sp-input-prefix,
	&.sp-input-with-suffix:disabled + .sp-input-suffix,
	&.sp-disabled,
	&.sp-disabled.sp-input-with-prefix + .sp-input-prefix,
	&.sp-disabled.sp-input-with-suffix + .sp-input-suffix {
		background-color: $input-background-color-disabled;
		border-color: $input-border-color-disabled;
		color: $input-color-disabled;
	}

	&:invalid,
	&.sp-input-with-prefix:invalid + .sp-input-prefix,
	&.sp-input-with-suffix:invalid + .sp-input-suffix,
	&.sp-error,
	&.sp-error.sp-input-with-prefix + .sp-input-prefix,
	&.sp-error.sp-input-with-suffix + .sp-input-suffix {
		background-color: $input-background-color-error;
		border-color: $input-border-color-error;
		color: $input-color-error;

		&:disabled,
		&.sp-input-with-prefix:disabled + .sp-input-prefix,
		&.sp-input-with-suffix:disabled + .sp-input-suffix,
		&.sp-disabled,
		&.sp-disabled.sp-input-with-prefix + .sp-input-prefix,
		&.sp-disabled.sp-input-with-suffix + .sp-input-suffix {
			background-color: $input-background-color-error-disabled;
			border-color: $input-border-color-error-disabled;
			color: $input-color-error-disabled;
		}
	}

	&::-webkit-input-placeholder {
		color: $input-placeholder-color;
	}

	&:-moz-placeholder {
		color: $input-placeholder-color;
	}

	&::-moz-placeholder {
		color: $input-placeholder-color;
	}

	&:-ms-input-placeholder {
		color: $input-placeholder-color;
	}

	// Do not show the clear icon in input elements
	&::-ms-clear {
		display: none;
	}

	// Do not show the reveal icon in password input elements
	&::-ms-reveal {
		display: none;
	}
}

.sp-input-prefix {
	@include border-radius($input-border-radius 0 0 $input-border-radius);
	@include font-t(7);
	background-color: $input-background-color;
	border: $input-border-width solid $color-grey-50;
	border-right: 0;
	box-sizing: border-box;
	color: $input-color;
	display: block;
	height: 26px;
	margin: 0;
	outline: 0;
	width: 26px;
}

.sp-input-suffix {
	@include border-radius(0 $input-border-radius $input-border-radius 0);
	@include font-t(7);
	background-color: $input-background-color;
	border: $input-border-width solid $color-grey-50;
	border-left: 0;
	box-sizing: border-box;
	color: $input-color;
	display: block;
	height: 26px;
	margin: 0;
	outline: 0;
	width: 26px;
}

.sp-input-diff {
	.sp-input-prefix,
	.sp-input-suffix {
		border-color: $input-border-color-diff;
	}
}

textarea {
	&.sp-input {
		min-height: 5em;
		padding-top: $input-border-focus-width - $input-border-width;
	}
}

.sp-input-changed .sp-input-prefix,
.sp-input-changed .sp-input-suffix {
	background-color: $input-background-color-changed;
}

.sp-error .sp-input-prefix,
.sp-error .sp-input-suffix {
	background-color: $input-background-color-error;
	border-color: $input-border-color-error;
	color: $input-color-error;
}

// everything defined below should be removed wen all the old inputs are removed

.sp-old-input-label {
	@include font-t(7);
	color: $input-label-color;

	&.sp-disabled {
		color: $input-label-color-disabled;
	}
}

.sp-input-error-label {
	@include font-t(7);
	color: $input-label-color-error;

	&.sp-disabled {
		color: $input-label-color-error-disabled;
	}
}
