/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-content-body > .sp-help {
	margin-left: 2em;
	margin-top: 2em;
}

.sp-help {
	@include flexbox-container(row, wrap, center, flex-start, flex-start);

	> .sp-panel {
		margin-bottom: 2em;
		margin-right: 2em;

		> .sp-panel-body {
			@include flexbox-container(column, nowrap, center);
			height: 10em;
			width: 20em;

			> .sp-help-explanation {
				@include flexbox-item(column, 1, 1);
				@include font-t(9);
				color: $color-text;
				margin: 1em;
			}

			> .sp-help-click {
				@include flexbox-item(column, 1, 1);
				@include font-t(7);
				color: $color-text;
				margin: 1em;
			}
		}
	}
}
