/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-virtualgridheadercell {
	@include flexbox-container(row, nowrap, center, center, center);
	background-color: $background-color-content;
	height: 100%;
	overflow: hidden;
	width: 100%;

	> .sp-virtualgridheadercell-title {
		@include flexbox-item(row, 0, 1);
		display: block;
		height: 100%;
		line-height: 1.5em;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	> .sp-virtualgridheadercell-sort {
		@include flexbox-item(row, 0, 0);
		display: block;
		font-size: 1.5em;
		height: 100%;
	}

	// make icon for "no sorting" less visible
	> .sp-virtualgridheadercell-sort.xl-icon-sort {
		color: $color-disabled-text;
	}

	// make icon for "no sorting" less visible
	> .sp-virtualgridheadercell-sort.xl-icon-sort-up,
	> .sp-virtualgridheadercell-sort.xl-icon-sort-down {
		color: $color-accent-brand;
	}

	// accent color on hover
	> .sp-virtualgridheadercell-sort:hover {
		color: $color-accent-brand-dark;
	}
}
