/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-loading-indicator {
	@include flexbox-container(column, nowrap, center, center);

	> .sp-loading-indicator-icon {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);
		padding: 1em;

		> .sp-icon {
			@include animation("animate-rotate 1.5s infinite linear");
			color: $color-grey-45;
			font-size: 1.5em;
		}
	}

	> .sp-loading-indicator-text {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);
		padding: 1em;
		padding-top: 0;

		> span {
			@include font-t(3);
			color: $color-grey-45;
		}
	}
}
