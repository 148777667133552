/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

$sp-raw-archive-record-tag-name-width: 15rem;
$sp-raw-archive-record-tag-value-width: 15rem;
$sp-raw-archive-record-tag-unit-width: 6rem;
$sp-raw-archive-record-tag-margin: .0625rem;

.sp-raw-archive-record-view-dialog {

	.sp-dialog-column {
		@include flexbox-item(row, 1, 1);
	}

	.sp-raw-archive-record-tag {
		@include flexbox-item(column, 1, 1, 1.125rem);
		@include flexbox-container(row);

		> .sp-raw-archive-record-tag-part {
			@include flexbox-item(row, 1, 1);
			margin: $sp-raw-archive-record-tag-margin;

			overflow: hidden;
			// ellipsis truncating
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		> .sp-raw-archive-record-tag-name {
			@include font-t(7);
			color: $color-text;
			width: $sp-raw-archive-record-tag-name-width;
		}

		> .sp-raw-archive-record-tag-value {
			@include font-t(9);
			color: $color-text;
			width: $sp-raw-archive-record-tag-value-width;
		}

		> .sp-raw-archive-record-tag-unit {
			@include font-t(10);
			color: $color-disabled-text;
			width: $sp-raw-archive-record-tag-unit-width;
		}
	}
}
