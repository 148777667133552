/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-generate-report-dialog-explanation {
	@include flexbox-item(column, 1, 0);
	@include font-t(7);
	color: $input-label-color;
	margin: 1em 0;
	width: 100%;
}

.sp-generate-report-dialog-section-what-where {
	.sp-dialog-column:first-child {
		.sp-input-label:first-child {
			@include flexbox-item(column, 1, 1);

			.sp-check-box-group-input {
				@include flexbox-item(column, 1, 1);
			}
		}
	}

	.sp-dialog-column:last-child {
		.sp-input-label:nth-child(2) {
			@include flexbox-item(column, 1, 1);

			.sp-check-box-group-input {
				@include flexbox-item(column, 1, 1);
			}
		}
	}
}
