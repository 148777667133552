/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-exlent-report-email-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;

	> .sp-exlent-report-email-dialog-form {
		@include flexbox-item(column, 1, 1);
	}
}
