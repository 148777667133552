/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-exlent-report-view-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	overflow: auto;
}

.sp-exlent-report-view-dialog-status {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(row, nowrap, center, center, center);
}

.sp-exlent-report-view-dialog-loading {
	@include flexbox-item(row, 0, 0);
	@include font-t(6);
	color: $color-unimportant-text;
}

.sp-exlent-report-view-dialog-error {
	@include flexbox-item(row, 0, 0);
	@include font-t(5);
	color: $color-error;
}

.sp-exlent-report-view-dialog > .sp-virtualgrid {
	@include flexbox-item(column, 1, 1);
}
