/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-modaldialog-placeholder {
	> .sp-modaldialog-overlay {
		outline: 0;

		> .sp-modaldialog {
			> .sp-modaldialog-content {
				@include font-t(5);
				color: $color-text;
			}
		}
	}
}

@media screen {
	.sp-modaldialog-placeholder {
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: $z-index-overlay;

		> .sp-modaldialog-overlay {
			@include flexbox-container(column, nowrap, center, center, stretch);
			background-color: $background-color-overlay;
			height: 100%;
			width: 100%;

			> .sp-modaldialog {
				@include flexbox-item(column, 0, 1, 100%, 100%);
				@include flexbox-container(column, nowrap, center, stretch, stretch);
				max-height: 98%;
				max-width: 80%;
				min-width: 25em;

				> .sp-modaldialog-header {
					@include flexbox-item(column, 0, 0, 2em, 2em);
					@include flexbox-container(row, nowrap, space-between, center, center);
					background-color: $background-color-header-20;
					border-bottom: 2px solid $outer-border-color;
					margin-top: 1em;
					position: relative;
					width: 100%;

					> .sp-modaldialog-title {
						@include flexbox-item(row, 0, 0);
						@include font-t(5);
						color: $color-text;
						margin-left: 1em;
						margin-right: 1em;
					}

					> .sp-modaldialog-close-button {
						@include border-radius(1em);
						height: 2em;
						position: absolute;
						right: -1em;
						top: -1em;
						width: 2em;
					}
				}

				> .sp-modaldialog-content {
					@include flexbox-item(column, 0, 1);
					@include flexbox-container(column);
					background-color: $background-color-content;
					overflow: auto;
				}

				> .sp-modaldialog-buttons {
					@include flexbox-item(column, 0, 0, 2em, 2em);
					@include flexbox-container(row, nowrap, flex-end, stretch, stretch);
					background-color: $background-color-header-20;
					border-top: 2px solid $outer-border-color;

					> .sp-modaldialog-button-group {
						@include flexbox-item(row, 0, 0);
						@include flexbox-container(row, nowrap, flex-end, stretch, stretch);
					}

					.sp-modaldialog-button-group + .sp-modaldialog-button-group {
						margin-left: 2px;
					}
				}
			}
		}
	}
}

@media print {
	.sp-modaldialog-placeholder > .sp-modaldialog-overlay > .sp-modaldialog {
		> .sp-modaldialog-content {
			height: auto !important;
		}

		> .sp-modaldialog-header,
		> .sp-modaldialog-buttons {
			display: none;
		}
	}
}
