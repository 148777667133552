/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$sp-slider-size: 1.5em;
$sp-slider-range-size: 2px;
$sp-slider-range-handle-size: .5em;
$sp-slider-range-handle-size-active: 1em;

$sp-slider-range-background-color: $color-grey-50;
$sp-slider-range-background-color-hover: $color-accent-brand-dark;
$sp-slider-range-background-color-active: $color-accent-brand;
$sp-slider-range-background-color-disabled: $color-grey-70;

$sp-slider-range-handle-background-color: $color-grey-10;
$sp-slider-range-handle-background-color-hover: $color-accent-brand-dark;
$sp-slider-range-handle-background-color-active: $color-accent-brand;
$sp-slider-range-handle-background-color-disabled: $color-grey-70;

.sp-slider {
	position: relative;

	> .sp-slider-ranges {
		position: absolute;

		> .sp-slider-range-total {
			background-color: $sp-slider-range-background-color-disabled;
			position: absolute;
		}

		> .sp-slider-range {
			background-color: $sp-slider-range-background-color;
			position: absolute;

			> .sp-slider-range-handle {
				@include border-radius($sp-slider-range-handle-size / 2);
				background-color: $sp-slider-range-handle-background-color;
				cursor: auto;
				height: $sp-slider-range-handle-size;
				position: absolute;
				width: $sp-slider-range-handle-size;
			}

			> .sp-slider-range-handle.sp-active {
				@include border-radius($sp-slider-range-handle-size-active / 2);
				background-color: $sp-slider-range-handle-background-color-active;
				height: $sp-slider-range-handle-size-active;
				width: $sp-slider-range-handle-size-active;
				z-index: $z-index-1;
			}

			> .sp-slider-range-handle.sp-disabled {
				background-color: $sp-slider-range-handle-background-color-disabled;
			}
		}

		> .sp-slider-range.sp-slider-range-movable:hover {
			background-color: $sp-slider-range-handle-background-color-hover;
		}

		> .sp-slider-range.sp-slider-range-resizable {
			> .sp-slider-range-handle:hover {
				background-color: $sp-slider-range-handle-background-color-hover;
			}

			> .sp-slider-range-handle.sp-active:hover {
				background-color: $sp-slider-range-handle-background-color-active;
			}
		}

		> .sp-slider-range.sp-active,
		> .sp-slider-range.sp-active:hover {
			background-color: $sp-slider-range-background-color-active;
		}

		> .sp-slider-range.sp-disabled {
			background-color: $sp-slider-range-background-color-disabled;

			> .sp-slider-range-handle {
				background-color: $sp-slider-range-handle-background-color-disabled;
			}
		}
	}
}

.sp-slider.sp-slider-horizontal {
	height: $sp-slider-size;
	min-height: $sp-slider-size;
	width: 100%;

	> .sp-slider-ranges {
		bottom: 0;
		left: $sp-slider-range-handle-size-active / 2;
		right: $sp-slider-range-handle-size-active / 2;
		top: 0;

		> .sp-slider-range-total {
			height: $sp-slider-range-size;
			left: 0;
			top: calc((#{$sp-slider-size} - #{$sp-slider-range-size}) / 2);
			width: 100%;
		}

		> .sp-slider-range {
			height: $sp-slider-range-size;
			left: 0;
			top: calc((#{$sp-slider-size} - #{$sp-slider-range-size}) / 2);
			width: 0;

			> .sp-slider-range-handle {
				top: calc(#{$sp-slider-range-size / 2} - #{$sp-slider-range-handle-size / 2});
			}

			> .sp-slider-range-handle.sp-active {
				top: calc(#{$sp-slider-range-size / 2} - #{$sp-slider-range-handle-size-active / 2});
			}

			> .sp-slider-range-handle-begin {
				left: -#{$sp-slider-range-handle-size / 2};
			}

			> .sp-slider-range-handle-begin.sp-active {
				left: -#{$sp-slider-range-handle-size-active / 2};
			}

			> .sp-slider-range-handle-end {
				right: -#{$sp-slider-range-handle-size / 2};
			}

			> .sp-slider-range-handle-end.sp-active {
				right: -#{$sp-slider-range-handle-size-active / 2};
			}
		}

		> .sp-slider-range.sp-slider-range-movable {
			cursor: ew-resize;
		}

		> .sp-slider-range.sp-slider-range-resizable {
			> .sp-slider-range-handle {
				cursor: ew-resize;
			}
		}
	}
}

.sp-slider.sp-slider-vertical {
	height: 100%;
	min-width: $sp-slider-size;
	width: $sp-slider-size;

	> .sp-slider-ranges {
		bottom: $sp-slider-range-handle-size-active / 2;
		left: 0;
		right: 0;
		top: $sp-slider-range-handle-size-active / 2;

		> .sp-slider-range-total {
			height: 100%;
			left: calc((#{$sp-slider-size} - #{$sp-slider-range-size}) / 2);
			top: 0;
			width: $sp-slider-range-size;
		}

		> .sp-slider-range {
			height: 0;
			left: calc((#{$sp-slider-size} - #{$sp-slider-range-size}) / 2);
			top: 0;
			width: $sp-slider-range-size;

			> .sp-slider-range-handle {
				left: calc(#{$sp-slider-range-size / 2} - #{$sp-slider-range-handle-size / 2});
			}

			> .sp-slider-range-handle.sp-active {
				left: calc(#{$sp-slider-range-size / 2} - #{$sp-slider-range-handle-size-active / 2});
			}

			> .sp-slider-range-handle-begin {
				top: -#{$sp-slider-range-handle-size / 2};
			}

			> .sp-slider-range-handle-begin.sp-active {
				top: -#{$sp-slider-range-handle-size-active / 2};
			}

			> .sp-slider-range-handle-end {
				bottom: -#{$sp-slider-range-handle-size / 2};
			}

			> .sp-slider-range-handle-end.sp-active {
				bottom: -#{$sp-slider-range-handle-size-active / 2};
			}
		}

		> .sp-slider-range.sp-slider-range-movable {
			cursor: ns-resize;
		}

		> .sp-slider-range.sp-slider-range-resizable {
			> .sp-slider-range-handle {
				cursor: ns-resize;
			}
		}
	}
}
