/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-gridcellboolean {
	@include flexbox-container(row, nowrap, center, center, center);
	height: 100%;
	white-space: nowrap;
	width: 100%;
}

.sp-gridcellboolean-value {
	@include flexbox-item(row, 0, 0);
	font-size: 1.5em;
}
