/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

@media screen  {
	.sp-application {
		@include flexbox-container(column);

		> .sp-application-top-bar {
			@include flexbox-item(column, 0, 0, 2em, 2em);
			@include flexbox-container(row, nowrap, space-between);
			background-color: $background-color-content;
			color: $color-title-text;

			> .sp-application-top-bar-menu-button {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row, nowrap, flex-start);

				> .sp-button {
					background-color: $background-color-mainmenu;
				}

				> .sp-button:hover {
					background-color: $button-dark-background-color-active;
				}

				> .sp-button.sp-active {
					background-color: $button-dark-background-color-active;
				}

				> .sp-button.sp-active:hover {
					background-color: $background-color-mainmenu;
				}
			}

			> .sp-application-top-bar-exlent-logo {
				@include flexbox-item(row, 0, 0, 6em, 6em);
				@include flexbox-container(row);
				background-image: url("/core/static/images/eXLent_RGB.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				cursor: pointer;
				height: 1em;
				margin: .5em 0 .5em 1em;
			}

			> .sp-application-top-bar-company-name {
				@include flexbox-item(row, 1, 1);
				@include flexbox-container(row, nowrap, center);
				height: 1em;
				margin: .5em 1em;
				min-width: 0;

				> span {
					@include font-t(13);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			> .sp-application-top-bar-current-user {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row);
				border-left: 1px solid $border-color;
			}

			> .sp-application-top-bar-alarm-button {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row);
				border-left: 1px solid $border-color;

				> .sp-button {
					color: $color-red;
				}
			}

			> .sp-application-top-bar-connected-indicator {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row);

				> .sp-connected-indicator {
					@include flexbox-item(row, 0, 0, 2em, 2em);
				}
			}
		}

		> .sp-splitter {
			@include flexbox-item(column, 1, 0, calc(100% - 2em), calc(100% - 2em));

			> .sp-splitter-bar {
				background-color: $color-mainmenu-border;

				&:hover {
					background-color: $color-mainmenu-border;
				}
			}

			> .sp-splitter-pane {
				> .sp-mainmenu {
					@include flexbox-item(column, 1, 1);
				}

				> .sp-content {
					@include box-shadow($content-box-shadow);
					@include flexbox-item(column, 1, 1, 100%, 100%);
					@include flexbox-container(column);
					background-color: $background-color-overall;

					> .sp-navbar {
						@include flexbox-item(column, 0, 0, 2em, 2em);
						padding: 2em;
						padding-bottom: 0;
					}

					> .sp-content-body {
						@include flexbox-item(column, 1, 0, calc(100% - 4em), calc(100% - 4em));
						@include flexbox-container(column);
						overflow: auto;

						> :first-child {
							@include flexbox-item(column, 1, 1, 100%, 100%);
						}
					}
				}
			}
		}

		> .sp-splitter.sp-splitter-vertical.sp-splitter-dragging > .sp-splitter-bar {
			background-color: $splitter-dragging-color;

			&:hover {
				background-color: $splitter-dragging-color;
			}
		}
	}
}

@media print {
	.sp-application > .sp-application-top-bar,
	.sp-application > .sp-splitter > .sp-splitter-pane:not(:last-child) {
		display: none;
	}
}
