/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-content-body > .sp-debug {
	margin-left: 2em;
	margin-top: 2em;
}

.sp-debug {
	@include flexbox-container(row, wrap, center, flex-start, flex-start);

	> .sp-panel {
		margin-bottom: 2em;
		margin-right: 2em;

		> .sp-panel-body {
			@include flexbox-container(column, nowrap, center);
			height: 10em;
			width: 20em;

			> .sp-combo-box-input {
				@include flexbox-item(column, 1, 1);
				margin: 1em;
			}
		}

	}
}
