/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-griddisplay {
	@include flexbox-item(column, 1, 1, 100%, 100%);
	@include flexbox-container(column);

	> .sp-grid-panel {
		@include flexbox-item(column, 1, 1, 100%, 100%);
		padding: 2em;
	}
}
