/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-grid-cell-flowx-link {
	@include flexbox-container(row, nowrap, flex-start, center, center);
	height: 100%;
	white-space: nowrap;
	width: 100%;

	> a {
		@include flexbox-item(row, 0, 0);
		// color for any <a> in a grid cell needs to be set on this level
		color: $color-text;
	}

	> a:active {
		color: $color-text;
	}

	> a:hover {
		color: $color-accent-brand;
	}
}

.sp-virtualgrid-selected {
	.sp-grid-cell-flowx-link {
		> a {
			color: $color-selected-text;
		}

		> a:active {
			color: $color-selected-text;
		}

		> a:hover {
			color: $color-accent-brand-dark;
		}
	}
}
