/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-duration-input {
	@include flexbox-container(row, nowrap, flex-start, stretch, stretch);

	> .sp-text-input {
		@include flexbox-item(row, 1, 1, 100%, 100%, auto);
		min-width: 8em;
	}

	> .sp-duration-input-button {
		@include flexbox-item(row, 0, 0, 2em, 2em, auto);
		background-image: url("/core/static/images/select.png");
		background-position: 100% 0;
		background-repeat: no-repeat;
		cursor: pointer;

		&:hover {
			background-position: 100% -24px;
			color: $input-color;
		}

		&:focus,
		.sp-active {
			background-position: 100% -48px;
		}
	}

	> .sp-duration-input-button:disabled,
	> .sp-duration-input-button.sp-disabled,
	> .sp-input:disabled + .sp-duration-input-button,
	> .sp-input.sp-disabled + .sp-duration-input-button {
		background-color: $input-background-color-disabled;
		background-position: 100% -72px;
		color: $input-color-disabled;
		cursor: auto;
	}

	> .sp-overlay {
		> .sp-duration-picker {
			@include box-shadow($default-box-shadow);
			background-color: $background-color-content;
			margin: .25em;
			pointer-events: all;
			width: 20em;
		}
	}

	&.sp-active {
		> .sp-text-input > .sp-input {
			border-bottom-width: $input-border-focus-width;
			padding-top: $input-border-focus-width - $input-border-width;
		}

		> .sp-duration-input-button {
			background-position: 100% -48px;
			border-bottom-width: $input-border-focus-width;
			padding-top: $input-border-focus-width - $input-border-width;
		}
	}
}
