/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-editprofiledialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;
}

.sp-editprofiledialog-general-errors {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap, space-around, stretch, flex-start);
	margin-bottom: 1em;

	> .sp-editprofiledialog-general-error {
		@include flexbox-item(column, 0, 0);
		margin-bottom: .25em;
	}

}

.sp-editprofiledialog-values {
	@include flexbox-item(column, 1, 1);

	> .sp-textinput {
		@include flexbox-item(column, 0, 0);
	}
}

.sp-editprofiledialog-password-requirements {
	@include flexbox-item(column, 0, 0);
	font-size: .8em;
	padding: .5em 0;

	> ul {
		list-style-position: outside;
		list-style-type: square;
		padding-left: 2em;
	}
}
