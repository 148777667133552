/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-date-time-picker {
	@include flexbox-container(column);

	> .sp-date-picker {
		@include flexbox-item(column, 1, 0);
		border-bottom: 2px solid $inner-border-color;
	}

	> .sp-time-picker {
		@include flexbox-item(column, 1, 0);
	}

	> .sp-time-zone-picker {
		border-top: 2px solid $inner-border-color;
		padding: 1em;
	}
}
