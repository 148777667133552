/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Non-semantic definitions
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@font-face {
	font-family: "Quantico-Bold";
	src: url("/core/static/font-quantico/Quantico-Bold.otf") format("opentype");
}
@font-face {
	font-family: "Source Sans Pro Regular";
	src: url("/core/static/font-source-sans-pro/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Source Sans Pro Semibold";
	src: url("/core/static/font-source-sans-pro/SourceSansPro-Semibold.ttf") format("truetype");
}
@font-face {
	font-family: "Source Sans Pro Bold";
	src: url("/core/static/font-source-sans-pro/SourceSansPro-Semibold.ttf") format("truetype");
}
@font-face {
	font-family: "Source Sans Pro Italic";
	src: url("/core/static/font-source-sans-pro/SourceSansPro-Italic.ttf") format("truetype");
}
@font-face {
	font-family: "Source Sans Pro BoldItalic";
	src: url("/core/static/font-source-sans-pro/SourceSansPro-BoldItalic.ttf") format("truetype");
}
@import "static/font-exlent/exlent";

// font used in <body> tag
$fontfamily-normal: Source Sans Pro Regular;

// icon font
$fontfamily-exlent-icons: exlent;

.sp-icon {
	-webkit-font-smoothing: none !important; // chrome hack to prevent aggressive anti-aliasing on small icons
	font-family: $fontfamily-exlent-icons !important;
}

// Super script styling for inline elements
.super-script {
	font-size: 80%;
	vertical-align: top;
}

// fonts as defined in style document
$font-family-t1: Source Sans Pro Regular;
$font-size-t1: 2em;
$font-family-t2: Source Sans Pro Bold;
$font-size-t2: 2em;
$font-family-t3: Source Sans Pro Regular;
$font-size-t3: 1.5em;
$font-family-t4: Source Sans Pro Bold;
$font-size-t4: 1.5em;
$font-family-t5: Source Sans Pro Regular;
$font-size-t5: 1em;
$font-family-t6: Source Sans Pro Semibold;
$font-size-t6: 1em;
$font-family-t7: Source Sans Pro Regular;
$font-size-t7: .875em;
$font-family-t8: Source Sans Pro Semibold;
$font-size-t8: .875em;
$font-family-t9: Source Sans Pro Bold;
$font-size-t9: .875em;
$font-family-t10: Source Sans Pro Italic;
$font-size-t10: .875em;
$font-family-t11: Source Sans Pro BoldItalic;
$font-size-t11: .875em;
$font-family-t12: Source Sans Pro Regular;
$font-size-t12: .75em;
$font-family-t13: Quantico-Bold;
$font-size-t13: 1em;
$font-family-t14: Quantico-Bold;
$font-size-t14: .875em;
$font-family-t15: Quantico-Bold;
$font-size-t15: .875em;
$font-family-t16: Consolas, monospace;
$font-size-t16: .875em;

$font-families-t: $font-family-t1, $font-family-t2, $font-family-t3, $font-family-t4, $font-family-t5, $font-family-t6, $font-family-t7, $font-family-t8, $font-family-t9, $font-family-t10, $font-family-t11, $font-family-t12, $font-family-t13, $font-family-t14, $font-family-t14, $font-family-t16;
$font-sizes-t: $font-size-t1, $font-size-t2, $font-size-t3, $font-size-t4, $font-size-t5, $font-size-t6, $font-size-t7, $font-size-t8, $font-size-t9, $font-size-t10, $font-size-t11, $font-size-t12, $font-size-t13, $font-size-t14, $font-size-t15, $font-size-t16;

// use this mixin to put a font T1-T16 from the style document
@mixin font-t($t) {
	font-family: nth($font-families-t, $t);
	font-size: nth($font-sizes-t, $t);
	@if $t == 13 or $t == 14 or $t == 15 {
		text-transform: uppercase;
	}
}

// signal colors (error, warning)
$color-green: rgb(0, 146, 69); // #009245
$color-red: rgb(234, 27, 17); // #ea1b11
$color-red-dark: rgb(152, 18, 11); // #98120b;
$color-red-darker: rgb(73, 4, 0); // #490400;
$color-red-light: rgb(244, 200, 200); // #f4c8c8;
$color-red-lighter: rgb(250, 230, 230); // #fae6e6;
$color-yellow: rgb(253, 220, 13);
$color-yellow-light: rgb(255, 236, 139); // #FFEC8B
$color-blue: rgb(39, 170, 225);
$color-blue-light: rgb(238, 248, 253);

// brand-specific accent colors
$color-spirit-orange: rgb(242, 101, 49);
$color-accent-brand: $color-spirit-orange;
$color-accent-brand-dark: rgb(155, 71, 39);
$color-accent-brand-darker: rgb(86, 30, 13);

.sp-accent-brand {
	background-color: $color-accent-brand !important;
}

.sp-hover-accent-brand:hover {
	background-color: $color-accent-brand !important;
}

.sp-accent-brand-dark {
	background-color: $color-accent-brand-dark !important;
}

.sp-hover-accent-brand-dark:hover {
	background-color: $color-accent-brand-dark !important;
}

// accent colors
$color-accent-1: rgb(17, 121, 176);
$color-accent-1-dark: rgb(5, 77, 114);
$color-accent-2: rgb(232, 176, 35);
$color-accent-2-dark: rgb(149, 116, 31);
$color-accent-3: rgb(139, 171, 62);
$color-accent-3-dark: rgb(81, 103, 47);
$color-accent-4: rgb(194, 41, 88);
$color-accent-4-dark: rgb(126, 35, 63);
$color-accent-5: rgb(42, 132, 102);
$color-accent-5-dark: rgb(35, 89, 71);
$color-accent-6: rgb(33, 184, 177);
$color-accent-6-dark: rgb(30, 120, 116);
$color-accent-7: rgb(97, 60, 122);
$color-accent-7-dark: rgb(68, 46, 83);
$color-accent-8: $color-red;
$color-accent-8-dark: $color-red-dark;
$color-accent-9: $color-accent-brand;
$color-accent-9-dark: $color-accent-brand-dark;
$accent-colors: $color-accent-1, $color-accent-2, $color-accent-3, $color-accent-4, $color-accent-5, $color-accent-6, $color-accent-7, $color-accent-8, $color-accent-9;
$accent-colors-dark: $color-accent-1-dark, $color-accent-2-dark, $color-accent-3-dark, $color-accent-4-dark, $color-accent-5-dark, $color-accent-6-dark, $color-accent-7-dark, $color-accent-8-dark, $color-accent-9-dark;

// introduce class for every accent color
@for $i from 1 through length($accent-colors) {
	$accent-color: nth($accent-colors, $i);

	.sp-accent#{$i} {
		background-color: $accent-color !important;
	}

	.sp-hover-accent#{$i}:hover {
		background-color: $accent-color !important;
	}

	.sp-border-accent#{$i} {
		border-color: $accent-color !important;
	}

	.sp-color-accent#{$i} {
		color: $accent-color !important;
	}

	.sp-color-hover-accent#{$i}:hover {
		color: $accent-color !important;
	}
}
@for $i from 1 through length($accent-colors-dark) {
	$accent-color-dark: nth($accent-colors-dark, $i);

	.sp-accent-dark#{$i} {
		background-color: $accent-color-dark !important;
	}

	.sp-hover-accent-dark#{$i}:hover {
		background-color: $accent-color-dark !important;
	}

	.sp-border-accent-dark#{$i} {
		border-color: $accent-color-dark !important;
	}

	.sp-color-accent-dark#{$i} {
		color: $accent-color-dark !important;
	}

	.sp-color-hover-accent-dark#{$i}:hover {
		color: $accent-color-dark !important;
	}
}

// grayscale, dark to light
$color-black: rgb(0, 0, 0); // #000000
$color-grey-10: rgb(30, 30, 30); // Grey 1 #1e1e1e
$color-grey-20: rgb(46, 46, 46); // Grey 2 #2e2e2e
$color-grey-30: rgb(60, 60, 60); // Grey 3 #3c3c3c
$color-grey-40: rgb(70, 70, 70); // Grey 4 #464646
$color-grey-41: rgb(90, 90, 90); // Grey 5 #5a5a5a
$color-grey-45: rgb(140, 140, 140); // Grey 6 #8c8c8c
$color-grey-50: rgb(180, 180, 180); // Grey 7 #b4b4b4
$color-grey-60: rgb(210, 210, 210); // Grey 8 #d2d2d2
$color-grey-70: rgb(235, 235, 235); // Grey 9 #ebebeb
$color-grey-80: rgb(245, 245, 245); // Grey 10 #f5f5f5
$color-white: rgb(255, 255, 255); // #ffffff

// Shadows
$default-box-shadow: "2px 2px 10px 1px rgba(0,0,0,0.26)";
$side-box-shadow: "4px 0px 5px 0px rgb(200,200,200)";
$content-box-shadow: "inset 4px 6px 12px 0 rgba(0, 0, 0, .2)";

// borders
$border-color: $color-grey-60;
$outer-border-color: $color-grey-50;
$inner-border-color: $color-grey-50;
$border-width: 1px;
$inner-border: $border-width solid $inner-border-color;
$outer-border: $border-width solid $outer-border-color;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Semantic definitions
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// signal colors
$color-info: $color-blue;
$color-good: $color-green;
$color-warn: $color-yellow;
$color-error: $color-red;
$color-error-light: $color-red-light;
$color-active: $color-accent-brand;
$color-diff: $color-blue;

.sp-warn {
	color: $color-warn !important;
}

.sp-error {
	color: $color-error !important;
}

// foregrounds
$color-text: $color-grey-30;
$color-title-text: $color-grey-10;
$color-selected-text: $color-white;
$color-unimportant-text: $color-grey-60;
$color-vague-text: $color-grey-50;
$color-error-text: $color-error;
$color-error-disabled-text: $color-error-light;
$color-disabled-text: $color-grey-50;
$color-placeholder-text: $color-grey-50;

// empty background
$background-color-overall: $color-grey-80;

// background of active element
$background-color-content: $color-white;
$background-color-content-alternate: $color-grey-80;
$background-color-unfetched: $color-grey-70;
$background-color-selected: $color-accent-brand;
$background-color-overlay: rgba(0, 0, 0, .5);

// background of header rows at different levels
$background-color-header-10: $color-grey-70;
$background-color-header-20: $color-grey-60;
$background-color-header-30: $color-grey-80;

// navigation menu
$color-mainmenu-border: $color-grey-40;
$color-mainmenu-text: $color-white;
$border-color-mainmenu: $color-grey-20;
// item background color dependent on indentation level
$background-color-mainmenu: $color-grey-10;
$background-color-mainmenu-level1: $color-grey-10;
$background-color-mainmenu-level2: $color-grey-20;
$background-color-mainmenu-level3: $color-grey-30;
$background-color-mainmenu-level4: $color-grey-40;
$mainmenu-level-colors: $background-color-mainmenu-level1, $background-color-mainmenu-level2, $background-color-mainmenu-level3, $background-color-mainmenu-level4;

// buttons

// Default
$button-dark-background-color: $color-grey-30;
$button-dark-foreground-color: $color-white;
// Default, Focus
$button-dark-border-color-focus: $color-accent-brand;
// Hover
$button-dark-background-color-hover: $color-accent-brand-dark;
$button-dark-foreground-color-hover: $color-white;
// Hover, Focus
$button-dark-border-color-hover-focus: $color-accent-brand-darker;
// Active
$button-dark-background-color-active: $color-accent-brand;
$button-dark-foreground-color-active: $color-white;
// Active, Focus
$button-dark-border-color-active-focus: $color-accent-brand-dark;
// Disabled
$button-dark-background-color-disabled: $color-grey-30;
$button-dark-foreground-color-disabled: $color-grey-41;
// Light Default
$button-light-background-color: $color-white;
$button-light-foreground-color: $color-text;
// Light Default, Focus
$button-light-border-color-focus: $color-accent-brand;
// Light Hover
$button-light-background-color-hover: $color-white;
$button-light-foreground-color-hover: $color-accent-brand-dark;
// Light Hover, Focus
$button-light-border-color-hover-focus: $color-accent-brand-darker;
// Light Active
$button-light-background-color-active: $color-white;
$button-light-foreground-color-active: $color-accent-brand;
// Light Active, Focus
$button-light-border-color-active-focus: $color-accent-brand-dark;
// Light Disabled
$button-light-background-color-disabled: $color-white;
$button-light-foreground-color-disabled: $color-grey-60;
// Red Default
$button-red-background-color: $color-white;
$button-red-foreground-color: $color-red;
// Red Default, Focus
$button-red-border-color-focus: $color-red-dark;
// Red Hover
$button-red-background-color-hover: $color-white;
$button-red-foreground-color-hover: $color-red-dark;
// Red Hover, Focus
$button-red-border-color-hover-focus: $color-red-darker;
// Red Disabled
$button-red-background-color-disabled: $color-white;
$button-red-foreground-color-disabled: $color-red-lighter;

// action menu
$action-menu-item-icon-background-color: $button-dark-background-color;
$action-menu-item-icon-foreground-color: $button-dark-foreground-color;
$action-menu-item-text-background-color: $color-white;
$action-menu-item-text-foreground-color: $color-grey-30;
$action-menu-item-icon-background-color-hover: $button-dark-background-color-hover;
$action-menu-item-icon-foreground-color-hover: $button-dark-foreground-color-hover;
$action-menu-item-text-background-color-hover: $color-accent-brand-dark;
$action-menu-item-text-foreground-color-hover: $color-white;
$action-menu-item-icon-background-color-disabled: $button-dark-background-color-disabled;
$action-menu-item-icon-foreground-color-disabled: $button-dark-foreground-color-disabled;
$action-menu-item-text-background-color-disabled: $color-white;
$action-menu-item-text-foreground-color-disabled: $color-grey-60;
$action-menu-item-icon-background-color-disabled-hover: $button-dark-background-color-disabled;
$action-menu-item-icon-foreground-color-disabled-hover: $button-dark-foreground-color-disabled;
$action-menu-item-text-background-color-disabled-hover: $color-white;
$action-menu-item-text-foreground-color-disabled-hover: $color-grey-60;
$action-menu-item-icon-background-color-active: $button-dark-background-color-active;
$action-menu-item-icon-foreground-color-active: $button-dark-foreground-color-active;
$action-menu-item-text-background-color-active: $color-accent-brand;
$action-menu-item-text-foreground-color-active: $color-white;
$action-menu-item-icon-background-color-active-hover: $button-dark-background-color-hover;
$action-menu-item-icon-foreground-color-active-hover: $button-dark-foreground-color-hover;
$action-menu-item-text-background-color-active-hover: $color-accent-brand-dark;
$action-menu-item-text-foreground-color-active-hover: $color-white;

// links
$color-link: $color-text;
$color-link-hover: $color-accent-brand;

a {
	@include font-t(7);
	color: $color-link;
	cursor: pointer;
}

a:active {
	color: $color-link;
}

a:hover {
	color: $color-link-hover;
}

// inputs
$input-padding: 0 .5em;
$input-border-radius: 4px;
$input-border-width: 1px;
$input-border-focus-width: 3px;
$input-border-color-diff: $color-diff;

$input-background-color: $color-white;
$input-background-color-changed: $color-blue-light;
$input-background-color-error: $color-error-light;
$input-background-color-disabled: $input-background-color;
$input-background-color-error-disabled: $input-background-color;

$input-border-color: $color-grey-50;
$input-border-color-error: $color-error;
$input-border-color-disabled: $color-grey-60;
$input-border-color-error-disabled: $color-error-light;

$input-color: $color-text;
$input-color-disabled: $color-disabled-text;
$input-color-error: $color-error-text;
$input-color-error-disabled: $color-error-disabled-text;

$input-placeholder-color: $color-grey-50;

$input-label-color: $color-text;
$input-label-color-disabled: $color-disabled-text;
$input-label-color-error: $color-error-text;
$input-label-color-error-disabled: $color-error-disabled-text;

// splitter
$splitter-color: $color-grey-60;
$splitter-border-color: $color-grey-30;
$splitter-hover-color: $color-grey-60;
$splitter-dragging-color:  $color-accent-brand;

// print colors
$color-text-print: $color-black;
$background-color-overall-print: $color-white;
