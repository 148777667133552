
.sp-check-box-list {
	@include flexbox-container(column, nowrap, flex-start, stretch);

	> .sp-check-box-list-labels {
		@include flexbox-item(column, 0, 0);
	}

	> .sp-check-box-list-boxes {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column);

		height: 0;
		overflow-x: auto;
		overflow-y: auto;

		> .sp-check-box-input {
			@include flexbox-item(column, 0, 0);
		}
	}
}
