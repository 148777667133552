/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-navbar {
	@include flexbox-container(row, wrap);

	> .sp-button {
		@include flexbox-item(row, 0, 0, 2em, 2em);
		text-align: center;
	}

	> .sp-button.sp-disabled {
		background-color: transparent;
		color: $color-link;
	}

	> .sp-navbar-breadcrumbs {
		@include flexbox-item(row, 1, 1, 2em, 2em);
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		margin-left: 1em;

		.sp-navbar-breadcrumb {
			@include flexbox-item(row, 0, 0);
			@include font-t(1);
			color: $color-link;
		}

		.sp-navbar-breadcrumb.sp-active {
			@include font-t(2);
		}

		.sp-navbar-breadcrumb:not(:last-of-type) {
			cursor: pointer;
		}

		.sp-navbar-breadcrumb:not(:last-of-type):hover {
			color: $color-link-hover;
		}

		.sp-navbar-breadcrumb-separator {
			@include flexbox-item(row, 0, 0, 1em, 1em);
			@include font-t(3);
			color: $color-grey-50;
			margin: auto .25em;
			text-align: center;
		}
	}
}

@media print {
	.sp-navbar {
		display: none;
	}
}
