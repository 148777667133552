/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-totalflow-device-address-input-helper {
	@include flexbox-container(row, nowrap, center, center);

	> .sp-text-input {
		@include flexbox-item(row, 1, 1, 60%, 60%);
	}

	> .sp-number-input {
		@include flexbox-item(row, 1, 1, 20%, 20%);
		padding-left: .5em;
	}
}
