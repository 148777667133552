/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-roleeditdialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;
}

.sp-roleeditdialog-general-errors {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap, space-around, stretch, flex-start);
	margin-bottom: 1em;

	> .sp-roleeditdialog-general-error {
		@include flexbox-item(column, 0, 0);
		margin-bottom: .25em;
	}

}

.sp-roleeditdialog-values {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(row, nowrap);

	> .sp-roleeditdialog-role {
		@include flexbox-item(row, 1, 1, 50%, 50%);
		@include flexbox-container(column, nowrap, flex-start, stretch);
		margin-right: 1em;

		> .sp-textinput {
			@include flexbox-item(column, 0, 0);
		}
	}

	> .sp-roleeditdialog-permissions {
		@include flexbox-item(row, 1, 1, 50%, 50%);
		@include flexbox-container(column, nowrap, stretch, stretch, stretch);

		> .sp-check-box-list {
			@include flexbox-item(column, 1, 1);
		}
	}
}
