/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-device-event-log-grid-panel {
	@include flexbox-container(column, nowrap, flex-start, stretch);

	> .sp-panel {
		@include flexbox-item(column, 1, 1);

		> .sp-panel-body {
			> .sp-grid-filter-bar {
				@include flexbox-item(column, 0, 1);
			}

			> .sp-virtualgrid-wrapper {
				@include flexbox-item(column, 1, 1);

				> .sp-virtualgrid {
					@include flexbox-container(column);
				}
			}
		}
	}
}

@media screen {
	.sp-device-event-log-grid-panel > .sp-panel > .sp-panel-body > .sp-virtualgrid-wrapper {
		@include scrollable-container;

		> .sp-virtualgrid {
			@include scrollable-item;
		}
	}
}
