/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$scrollbar-background: transparent;
$scrollbar-thumb: $inner-border-color;

::-webkit-scrollbar-track {
	background-color: $scrollbar-background;
}

::-webkit-scrollbar {
	background-color: $scrollbar-background;
	height: 10px;
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background-color: $scrollbar-thumb;
	// background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, $color-grey-80),	color-stop(.5, $scrollbar-background), to($scrollbar-background));
}

// For Internet Explorer
body {
	scrollbar-3dlight-color: $scrollbar-background;
	scrollbar-arrow-color: $scrollbar-thumb;
	scrollbar-base-color: $scrollbar-background;
	scrollbar-face-color: $scrollbar-thumb;
	scrollbar-highlight-color: $scrollbar-background;
	scrollbar-shadow-color: $scrollbar-background;
	scrollbar-track-color: $scrollbar-background;
}
