/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-duration-range-input {
	@include flexbox-container(row);

	> .sp-duration-range-input-begin {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(column);

		> .sp-duration-input {
			@include flexbox-item(column, 1, 0);
		}
	}

	> .sp-duration-range-input-end {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(column);
		margin-left: .5em;

		> .sp-duration-input {
			@include flexbox-item(column, 1, 0);
		}
	}
}
