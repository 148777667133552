/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-duration-picker {
	@include flexbox-container(column, nowrap, center, center);
	background-color: $background-color-content;
	padding: .5em;

	> .sp-number-input {
		@include flexbox-item(column, 1, 1);
		width: 100%;

		> input {
			text-align: center;
		}
	}

	> .sp-combo-box-input {
		@include flexbox-item(column, 1, 1);
		width: 100%;
	}

	> .sp-duration-picker-unit {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(row, wrap, center);
		padding-top: .5em;

		> .sp-button {
			@include flexbox-item(row, 0, 0);
			background-color: $color-grey-80;
			height: 2em;
			margin: .25em;

			> .sp-button-text {
				padding: 0 .5em;
			}

			&.sp-active {
				background: $button-dark-background-color-active;
				color: $button-dark-foreground-color-active;
			}
		}
	}
}
