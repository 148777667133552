/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-radio-button {
	@include border-radius($input-border-radius);
	@include flexbox-container(column);
	box-sizing: border-box;
	color: $color-grey-41;
	outline: 0;
	padding: $input-border-width;

	> label {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row, nowrap, flex-start, center);
		cursor: pointer;

		> input {
			&[type="checkbox"] {
				display: none;

				& + span {
					// icon
					font-size: 26px;
					line-height: 26px;
				}

				& + span + span {
					@include font-t(7);
				}
			}
		}
	}

	&.sp-radio-button-focus:focus {
		border: $input-border-width solid $color-grey-50;
		padding: 0;
	}

	&.sp-disabled label {
		cursor: default;
	}
}
