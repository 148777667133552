/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

@import "../../../../../platform/lib/webapp/reports/archive-record-report";

.sp-archive-record-report-header {
	@include flexbox-item(column, 0, 0, 125px, 125px);
	@include flexbox-container(column, nowrap, center, center);
	@include font-t(2);
	position: relative;

	img {
		left: 0;
		position: absolute;
		width: 200px;
	}
}
