/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-measurement-dashboard {
	@include flexbox-container(column);
	margin: 2em 0;

	> .sp-loading-indicator {
		@include flexbox-item(column, 1, 0);
	}

	> .sp-measurement-dashboard-sites {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);

		> .sp-accordion {
			@include flexbox-item(column, 0, 0);

			> .sp-accordion-item-heading {
				margin-left: 2em;
				margin-right: 2em;
			}

			.sp-accordion-item-heading-content {
				@include flexbox-container(column);

				> .sp-measurement-dashboard-site {
					@include flexbox-item(column, 0, 0, 2em, 2em);
					@include flexbox-container(row);
					padding-left: 2em;

					> .sp-measurement-dashboard-site-info {
						@include flexbox-item(row, 0, 0, 10em, 10em);
						@include flexbox-container(row, nowrap, flex-start, center);

						> span {
							@include flexbox-item(row, 0, 0);
							@include flexbox-container(row, nowrap, flex-start, center);
							color: $color-text;

							&:first-child > .sp-icon {
								font-size: 1.5em;
							}

							&:last-child {
								@include font-t(3);
							}
						}

						&.sp-error > span {
							color: $color-error;
						}

						&.sp-disabled > span {
							color: $color-disabled-text;
						}

						&.sp-measurement-dashboard-site-info[data-tag="NAME"] {
							@include flexbox-item(row, 1, 1);

							> span:last-child {
								@include font-t(4);
							}
						}
					}
				}
			}

			.sp-accordion-item-content {
				@include flexbox-container(column);

				> .sp-measurement-dashboard-segments {
					@include flexbox-item(column, 0, 0);
					@include flexbox-container(row, wrap, center);
					margin: 1em 0;

					> .sp-panel {
						@include flexbox-item(row, 0, 0, 8.5em, 8.5em);
						margin: 1em;

						.sp-panel-body {
							@include flexbox-container(column);
							overflow: visible;

							> .sp-measurement-dashboard-segment {
								@include flexbox-item(column, 0, 0);
								@include flexbox-container(column);

								> .sp-measurement-dashboard-segment-info {
									@include flexbox-item(column, 0, 0, 3.5em, 3.5em);
									@include flexbox-container(column, nowrap, space-between, center);
									padding-bottom: 1em;
									padding-top: 1em;

									> span {
										@include flexbox-item(row, 0, 0);
										@include flexbox-container(row, nowrap, flex-start, center);
										color: $color-text;

										&:first-child {
											> .sp-icon {
												font-size: 1.5em;
											}

											> span:last-child {
												@include font-t(10);
											}
										}

										&:last-child {
											@include font-t(3);
										}
									}

									&.sp-error > span {
										color: $color-error;
									}

									&.sp-disabled > span {
										color: $color-disabled-text;
									}
								}
							}
						}

						.sp-panel-footer {
							@include flexbox-container(row);

							> .sp-button {
								@include flexbox-item(row, 1, 0);
							}
						}
					}
				}
			}
		}
	}
}
