/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-modaldialog-content > .sp-grid-edit-dialog-content {
	@include flexbox-item(column, 0, 1);
}

.sp-grid-edit-dialog-content {
	@include flexbox-container(column);
	background-color: $background-color-content;
	overflow: auto;
	position: relative;

	> .sp-grid-edit-dialog-header {
		@include flexbox-item(column, 1, 0, 5em, 5em);
		@include flexbox-container(row);
		border-bottom: 1px solid $inner-border-color;
		max-height: 15em;
		overflow: auto;

		> .sp-grid-edit-dialog-errors {
			@include flexbox-item(row, 0, 0);
			@include flexbox-container(column);
			padding: 1em;

			> .sp-grid-edit-dialog-error {
				@include flexbox-item(column, 0, 0, 1.3125em, 1.3125em);
				@include font-t(7);
				color: $color-error;
				line-height: 1.3125em;

				&:last-child {
					padding-bottom: 1em;
				}
			}
		}

		> .sp-grid-edit-dialog-errors-hide-button {
			@include flexbox-item(row, 1, 1, auto, auto, center);
			position: absolute;
			right: 1em;

			> .sp-icon {
				color: $color-red;
				cursor: pointer;
				font-size: 1.5em;
			}

			> .sp-icon:hover {
				color: $color-red-dark;
			}
		}
	}

	// direct use of columns without sections
	> .sp-dialog-columns {
		@include flexbox-item(column, 1, 0);
	}

	> .sp-dialog-sections > .sp-accordion > .sp-accordion-item-content > .sp-dialog-section > .sp-dialog-columns {
		@include flexbox-item(column, 1, 0);
		margin: .5em 0;

		> .sp-dialog-column {
			@include flexbox-item(row, 0, 1, calc(350px + 1em));
		}

		&.sp-dialog-columns-1 > .sp-dialog-column {
			@include flexbox-item(row, 1, 1);
		}
	}
}

.sp-grid-edit-dialog-content.sp-ged-horizontal-labels {
	> .sp-dialog-sections > .sp-accordion > .sp-accordion-item-content > .sp-dialog-section > .sp-dialog-columns {
		> .sp-dialog-column {
			@include flexbox-item(row, 0, 1, calc(350px + 180px + 1em));
		}

		&.sp-dialog-columns-1 > .sp-dialog-column {
			@include flexbox-item(row, 1, 1);
		}
	}
}
