/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

$switch-button-border-size: 1px;
$switch-button-state-margin: .1875rem;
$switch-button-width: 3.25rem + $switch-button-state-margin * 2;
$switch-button-height: 1.5rem;
$switch-button-state-width: 1.5rem;
$switch-button-state-height: $switch-button-height - $switch-button-state-margin * 2;

.sp-switch {
	outline: 0;

	&:focus .sp-switch-button-state {
		background: $color-grey-30;
	}

	&.sp-changed .sp-switch-button {
		background: $color-blue-light;
	}

	&.sp-different .sp-switch-button {
		border-color: $color-blue;
	}

	&.sp-disabled {
		label {
			cursor: default;
		}

		.sp-switch-button {
			background: $color-white;
			border-color: $color-grey-80;

			.sp-switch-button-state {
				background: $color-grey-80;
			}
		}
	}

	&.sp-error {
		.sp-switch-button {
			border-color: $color-error;
		}

		.sp-switch-button-state {
			background: $color-error;
		}

		.sp-switch-text {
			color: $color-error;
		}

		&:focus .sp-switch-button-state {
			background: $color-red-dark;
		}
	}

	&.sp-error.sp-disabled {
		.sp-switch-button {
			border-color: $color-red-lighter;
		}

		.sp-switch-button-state {
			background: $color-red-lighter;
		}

		.sp-switch-text {
			color: $color-red-lighter;
		}
	}

	label {
		@include flexbox-container(row);
		cursor: pointer;
	}
}

.sp-switch-button {
	@include border-radius($switch-button-height / 2);
	@include flexbox-item(row, 0, 0);
	background: $color-white;
	border: $switch-button-border-size solid $color-grey-50;
	height: $switch-button-height;
	position: relative;
	width: $switch-button-width;
}

.sp-switch-button-state {
	@include border-radius($switch-button-state-height / 2);
	background: $color-grey-50;
	height: $switch-button-state-height;
	left: calc(50% - #{$switch-button-state-width} / 2);
	margin: $switch-button-state-margin 0;
	position: absolute;
	width: $switch-button-state-width;

	&.sp-switch-state-off {
		left: $switch-button-state-margin;
	}

	&.sp-switch-state-on {
		left: auto;
		right: $switch-button-state-margin;
	}
}

.sp-switch-text {
	@include flexbox-item(row, 1, 0);
	@include font-t(7);
	line-height: $switch-button-state-height;
	padding: calc(#{$switch-button-state-margin} + #{$switch-button-border-size}) 0;
	padding-left: .5rem;
}
