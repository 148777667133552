/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-subscription-settings-dialog {
	@include flexbox-item(column, 1, 0);
	@include flexbox-container(column);

	.sp-subscription-settings {
		@include flexbox-item(column, 1, 1);
		margin: 1em;

		.sp-subscription-settings-dialog-error {
			@include flexbox-item(column, 0, 0);
			margin: 1em 0;
		}
	}
}
