/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-time-zone-input > .sp-input {
	-moz-appearance: none;
	-webkit-appearance: none;
	background-color: $input-background-color;
	background-image: url("/core/static/images/select.png");
	background-position: 100% 0;
	background-repeat: no-repeat;

	> option {
		background-color: $input-background-color;
		color: $input-color;

		&:hover {
			background-color: $color-grey-80;
			color: $input-color;
		}

		&:disabled,
		&.sp-disabled {
			background-color: $input-background-color-disabled;
			color: $input-color-disabled;
		}
	}

	&:hover {
		background-color: $input-background-color;
		background-position: 100% -24px;
		color: $input-color;
	}

	&:focus,
	.sp-active {
		background-position: 100% -48px;
	}

	&:disabled,
	&.sp-disabled {
		background-color: $input-background-color-disabled;
		background-position: 100% -72px;
		color: $input-color-disabled;

		&::-ms-value {
			color: $input-color-disabled;
		}
	}

	&:invalid,
	&.sp-error {
		&::-ms-value {
			color: $input-color-error;
		}

		&:disabled,
		&.sp-disabled {
			&::-ms-value {
				color: $input-color-error-disabled;
			}
		}
	}

	&::-ms-expand {
		display: none;
	}

	&::-ms-value {
		color: $input-color;
	}

	&.sp-placeholder {
		color: $input-placeholder-color;
	}

	&.sp-error:hover {
		background-color: $input-background-color-error;
	}

	&.sp-error {
		background-color: $input-background-color-error;
	}
}
