/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-admin-email-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 0 .5em;

	> .sp-admin-email-dialog-form {
		@include flexbox-item(column, 1, 1);
		overflow-y: auto;

		> .sp-admin-email-dialog-error {
			@include flexbox-item(column, 0, 0, auto, auto);
			color: $color-error-text;
			margin: 1em .5em .5em;
		}

		> .sp-dialog-columns {
			@include flexbox-item(column, 1, 1);
			@include flexbox-container(row, wrap, flex-start, stretch, stretch);

			> .sp-dialog-column {
				@include flexbox-item(row, 1, 1, auto, auto, flex-start);
				margin: 0 .5em;

				.sp-text-input {
					min-width: 350px;
				}
			}
		}
	}
}

.sp-admin-email-addresses {
	font-size: .875em;
	margin: .5em;
}

.sp-admin-email-ul-dashed {
	font-size: .875em;
	list-style-type: none;
	margin: 0;

	> li {
		text-indent: .25em;

		&::before {
			content: "- ";
		}
	}
}
