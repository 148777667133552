/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-setting-edit {
	@include flexbox-container(row, nowrap, space-between);

	> .sp-setting-edit-input {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(row);
		padding-right: 1em;

		> :first-child {
			@include flexbox-item(row, 1, 1);
		}
	}

	> .sp-setting-edit-buttons {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(row, nowrap, flex-start, flex-end);

		> .sp-button {
			@include flexbox-item(row, 1, 1, 2em, 2em);
			height: 2em;
		}
	}
}
