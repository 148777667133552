/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-actionbar-menu-placeholder {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.sp-actionmenu-overlay {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}


$actionmenu-action-height: 2em;
$actionmenu-group-gap: 3px;

.sp-actionmenu-content {
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
	@include box-shadow($default-box-shadow);
	background-color: $background-color-header-20;
	overflow-x: hidden;
	overflow-y: auto;
}

.sp-actionmenu-group {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column, nowrap, stretch, stretch, stretch);
}

.sp-actionmenu-group + .sp-actionmenu-group {
	margin-top: $actionmenu-group-gap;
}

.sp-actionmenu-item {
	@include flexbox-item(column, 0, 0, 2em, 2em);
	@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
	cursor: pointer;

	> .sp-actionmenu-item-icon {
		@include flexbox-item(row, 0, 0, 2em, 2em);
		@include flexbox-container(column, nowrap, center, center, stretch);
		background-color: $action-menu-item-icon-background-color;
		color: $action-menu-item-icon-foreground-color;

		> .sp-icon {
			font-size: 1.5em;
		}
	}

	> .sp-actionmenu-item-text {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(column, nowrap, center, stretch, stretch);
		background-color: $action-menu-item-text-background-color;
		color: $action-menu-item-text-foreground-color;

		> .sp-button-text {
			@include font-t(7);
			margin-left: .5em;
		}
	}
}

.sp-actionmenu-item:hover {
	> .sp-actionmenu-item-icon {
		background-color: $action-menu-item-icon-background-color-hover;
		color: $action-menu-item-icon-foreground-color-hover;
	}

	> .sp-actionmenu-item-text {
		background-color: $action-menu-item-text-background-color-hover;
		color: $action-menu-item-text-foreground-color-hover;
	}
}

.sp-actionmenu-item.sp-disabled {
	cursor: default;
	pointer-events: none;

	> .sp-actionmenu-item-icon {
		background-color: $action-menu-item-icon-background-color-disabled;
		color: $action-menu-item-icon-foreground-color-disabled;
	}

	> .sp-actionmenu-item-text {
		background-color: $action-menu-item-text-background-color-disabled;
		color: $action-menu-item-text-foreground-color-disabled;
	}
}

.sp-actionmenu-item.sp-disabled:hover {
	cursor: default;
	pointer-events: none;

	> .sp-actionmenu-item-icon {
		background-color: $action-menu-item-icon-background-color-disabled-hover;
		color: $action-menu-item-icon-foreground-color-disabled-hover;
	}

	> .sp-actionmenu-item-text {
		background-color: $action-menu-item-text-background-color-disabled-hover;
		color: $action-menu-item-text-foreground-color-disabled-hover;
	}
}

.sp-actionmenu-item.sp-active {
	> .sp-actionmenu-item-icon {
		background-color: $action-menu-item-icon-background-color-active;
		color: $action-menu-item-icon-foreground-color-active;
	}

	> .sp-actionmenu-item-text {
		background-color: $action-menu-item-text-background-color-active;
		color: $action-menu-item-text-foreground-color-active;
	}
}

.sp-actionmenu-item.sp-active:hover {
	> .sp-actionmenu-item-icon {
		background-color: $action-menu-item-icon-background-color-active-hover;
		color: $action-menu-item-icon-foreground-color-active-hover;
	}

	> .sp-actionmenu-item-text {
		background-color: $action-menu-item-text-background-color-active-hover;
		color: $action-menu-item-text-foreground-color-active-hover;
	}
}
