/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-connected-indicator {
	@include flexbox-container(row, nowrap, center, center, center);
	background-color: $background-color-content;

	> .sp-icon {
		@include flexbox-item(row, 0, 0);
		color: $button-light-foreground-color;
		font-size: 1.5em;
	}
}

.sp-connected-indicator.sp-error {
	background-color: $color-error;

	> .sp-icon {
		color: $color-white;
	}
}
