/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-tab-bar {
	@include flexbox-container(row, wrap, flex-start, stretch, flex-start);
	margin-right: 2em;
}

.sp-tab-bar-tab {
	@include flexbox-item(row, 1, 1);
	@include flexbox-container(column, nowrap, center, center);
	border-bottom: 2px solid $background-color-header-20;
	border-top: 2px solid $background-color-header-20;
	cursor: pointer;
	height: 2em;
	margin: 2em 0 0 2em;

	> .sp-tab-bar-tab-text {
		@include flexbox-item(column, 0, 0);
		@include font-t(5);
		margin: .125em 0;
	}

	&.sp-tab-bar-selected {
		background-color: $background-color-header-20;

		> .sp-tab-bar-tab-text {
			@include font-t(6);
		}
	}

	&.sp-disabled {
		background-color: transparent;
		border-color: $color-grey-80;
		color: $color-grey-60;
		cursor: default;
	}
}
