/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-time-picker {
	@include flexbox-container(row, nowrap, center, center);
	background-color: $background-color-content;
	padding: 0 .5em;

	> .sp-time-picker-component-separator {
		@include flexbox-item(row, 0, 0);

		> .sp-icon {
			font-size: 1.5em;
		}
	}

	> .sp-time-picker-component {
		@include flexbox-item(row, 1, 1, 100%, 100%);
		@include flexbox-container(row);
		padding: 0 .5em;

		> .sp-number-spinner {
			@include flexbox-item(row, 1, 1, 100%, 100%);
		}
	}
}
