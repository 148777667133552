
.sp-device-info {
	@include flexbox-container(column, nowrap, flex-start, stretch);
	box-sizing: border-box;
	overflow-y: auto;
	padding-top: 2em;


	> .sp-device-info-row {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(column);
		padding: 0 2em 2em;

		> .sp-panel {
			@include flexbox-item(column, 1, 0);
		}
	}

	> .sp-device-info-row.sp-device-info-error {
		@include flexbox-container(column, nowrap, stretch, stretch, stretch);
		padding: 2emm;

		> .sp-device-info-feed-error-text {
			@include flexbox-item(column, 1, 0);
			@include font-t(2);
			background-color: $background-color-overall;
			color: $color-error;
			padding: .5em;
			text-align: center;
		}
	}

	> .sp-device-info-row.sp-device-info-loading {
		> .sp-loading-indicator {
			@include flexbox-item(column, 1, 0);
		}
	}

	> .sp-device-info-row.sp-device-info-real-time-values {
		.sp-device-info-real-time-data-tags {
			@include flexbox-item(column, 0, 0);
			@include flexbox-container(row, wrap, space-around, stretch, flex-start);
			box-sizing: border-box;
			padding: .5em 0 0 .5em;

			.sp-dashboard-tag {
				@include flexbox-item(row, 1, 0, 10em, 10em);
				margin: 0 .5em .5em 0;
			}
		}
	}

	> .sp-device-info-row.sp-device-info-exlent-configuration,
	> .sp-device-info-row.sp-device-info-device-configuration {
		.sp-panel-body {
			@include flexbox-container(row, wrap);
		}

		.sp-device-info-device-info-column,
		.sp-device-info-device-config-column {
			@include flexbox-item(row, 1, 1, 22em, 22em);
			@include flexbox-container(column, nowrap, flex-start);
			margin: .5em 0 .5em .5em;

			&:last-child {
				margin-right: .5em;
			}

			> .sp-device-info-panel-row {
				@include flexbox-item(column, 0, 0);
				@include flexbox-container(row, nowrap, flex-start, center);
				min-height: 1.375em;

				> .sp-device-info-cell {
					@include flexbox-item(row, 1, 1, 50%, 50%);
					padding: .2em .5em;
				}

				> .sp-device-info-cell-title {
					@include flexbox-item(row, 1, 1, 35%, 35%);
					color: $color-text;
					min-width: 10em;

					> span {
						@include font-t(7);
					}
				}

				> .sp-device-info-cell-value {
					@include flexbox-item(row, 1, 1, 65%, 65%);
					color: $color-text;
					min-width: 10em;

					> span {
						@include font-t(9);
					}

					.sp-device-info-value-link { // override more generic <a/> tag styling
						@include font-t(9);
					}
				}

				> .sp-device-info-cell-value.sp-disabled {
					color: $color-disabled-text;
				}

				> .sp-device-info-cell-value.sp-error {
					color: $color-error-text;
				}

				a {
					color: $color-text;
				}

				a:active {
					color: $color-text;
				}

				a:hover {
					color: $color-accent-brand;
				}
			}

			> .sp-device-info-panel-row:nth-child(2n+1) {
				background-color: $background-color-content-alternate;
			}
		}
	}

	> .sp-device-info-row.sp-device-info-alarms > .sp-alarms-grid-panel > .sp-panel > .sp-panel-body > .sp-alarms-grid-panel-grid,
	> .sp-device-info-row.sp-device-info-event-log > .sp-device-event-log-grid-panel > .sp-panel > .sp-panel-body > .sp-virtualgrid-wrapper {
		@include flexbox-item(column, 1, 0, 10em, 10em);
	}
}
