/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$panel-header-background: $background-color-header-20;
$panel-body-background: $background-color-content;
$panel-footer-background: $background-color-header-20;

.sp-panel {
	@include flexbox-container(column);

	> .sp-panel-header {
		@include flexbox-item(column, 0, 0, 2em, 2em);
		@include flexbox-container(row);
		border-bottom: 2px solid $inner-border-color;

		> .sp-panel-header-title {
			@include flexbox-container(row, nowrap, space-between, center);
			@include flexbox-item(row, 1, 1);
			padding: 0 1em;
		}
	}

	> .sp-panel-body {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column);
	}

	> .sp-panel-footer {
		@include flexbox-item(column, 0, 0, 2em, 2em);
		@include flexbox-container(row, nowrap, flex-end);
		border-top: 2px solid $inner-border-color;

		> * {
			@include flexbox-item(row, 0, 0);
		}
	}
}

@media screen  {
	.sp-panel {
		@include box-shadow($default-box-shadow);

		> .sp-panel-header {
			background: $panel-header-background;
		}

		> .sp-panel-body {
			background: $panel-body-background;
			overflow: auto;
		}

		> .sp-panel-footer {
			background: $panel-footer-background;
		}
	}
}

@media print  {
	.sp-panel {
		border: $outer-border;

		> .sp-panel-header {
			background: transparent;
		}

	}
}
