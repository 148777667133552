/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

/* Mixins for flexbox layouts */

// internal, do not use
@mixin flexbox-flow($direction, $wrap) {
	-ms-flex-flow: $direction $wrap;
	-webkit-flex-flow: $direction $wrap;
	flex-flow: $direction $wrap;
}

/**
 * Control spacing in the cross direction (don't confuse with align-self or justify-content)
 * @param align flex-start | flex-end | center | space-between | space-around | stretch
 */
@mixin flexbox-align-content($align) {
	-webkit-align-content: $align;
	align-content: $align;
}

/**
 * The align-self CSS property aligns flex items of the current flex line overriding the align-items value.
 * If any of the flex item's cross-axis margin is set to auto, then align-self is ignored.
 * @param align auto | flex-start | flex-end | center | baseline | stretch
 */
@mixin flexbox-align-self($align) {
	-webkit-align-self: $align;
	align-self: $align;
}

/**
 * Use this on a flex container
 *
 * @param direction column | column-reverse | row | row-reverse
 * @param wrap Optional. nowrap | wrap | wrap-reverse
 *
 * @param justify-content Optional. Only for containers where all items can NOT grow.
 *                       The CSS justify-content property defines how a browser distributes available
 *                       space between and around elements when aligning flex items in the main-axis of the current line.
 *                       The alignment is done after the lengths and auto margins are applied, meaning that, if there is
 *                       at least one flexible element, with flex-grow different than 0, it will have no effect as there
 *                       won't be any available space. flex-start | flex-end | center | space-between | space-around
 *                       E.g. for a row flex-direction, this is horizontal align
 *
 * @param align-items Optional. Specifies how items are aligned on the cross-axis. flex-start | flex-end | center | baseline | stretch
 *                              e.g. for a row flex-direction, this is vertical align.
 *
 * @param align-content Optional. Only for wrapping containers with more than one line.
 *                      The CSS align-content property aligns a flex container's lines within the flex container
 *                      when there is extra space on the cross-axis. This property has no effect on single line flexible boxes.
 *                      flex-start | flex-end | center | space-between | space-around | stretch
 */
@mixin flexbox-container($direction, $wrap: nowrap, $justify-content: flex-start, $align-items: stretch, $align-content: stretch) {
	@include flexbox-align-content($align-content);// needed to remove the vertical space between wrapped lines
	@include flexbox-flow($direction, $wrap);
	align-items: $align-items; // needed to prevent item height taking 100%
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	justify-content: $justify-content; // needed to space items equally
}

/**
 * Use this on a flex item for a column-direction flex layout
 * @param direction column | column-reverse | row | row-reverse
 * @param grow A number indicating relative grow factor for available space
 * @param shrink A number indicating relative shrink factor for available space
 * @param basis Default height/width of the item; must be an explicit value (no auto/content)
 * @param fallback For older browsers, height/width; all CSS height/width property values allowed but not auto
 * @param align-self Optional. Overrides the align-items property of the container for one item. Does not work if margin is set to auto.
 *                   auto | flex-start | flex-end | center | baseline | stretch
 */
@mixin flexbox-item($direction, $grow, $shrink, $basis: auto, $fallback: auto, $align-self: auto) {
	@include flexbox-align-self($align-self);
	-moz-box-flex: $grow;
	-ms-flex: $grow;
	-webkit-box-flex: $grow;
	-webkit-flex: $grow;
	flex-basis: $basis;
	flex-grow: $grow;
	flex-shrink: $shrink;
	@if $direction == column or $direction == column-reverse {
		height: $fallback;
	} @else {
		width: $fallback;
	}
}

/**
 * Use on items if the display order differs from the HTML element order
 * @param order A number indicating the order
 */
@mixin flexbox-order($order) {
	-moz-box-ordinal-group: $order;
	-ms-flex-order: $order;
	-webkit-box-ordinal-group: $order;
	-webkit-order: $order;
	order: $order;
}
