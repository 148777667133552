/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-auth-settings {
	@include flexbox-container(row, wrap, flex-start, flex-start);
	padding: 2em;

	> .sp-panel {
		@include flexbox-item(row, 0, 1, 25em, 25em);

		> .sp-panel-body {
			> .sp-setting-edit {
				@include flexbox-item(column, 0, 0, 3.5em, 3.5em);
				border-bottom: 2px solid $inner-border-color;
				padding: 1em;
			}

			> .sp-setting-edit:last-child {
				border-bottom: 0;
			}
		}
	}
}
