/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-number-spinner {
	@include flexbox-container(column, nowrap, center, stretch, center);

	> .sp-button {
		@include flexbox-item(column, 0, 0, 2em, 2em, center);
	}

	> .sp-number-input {
		@include flexbox-item(column, 0, 0, 26px, 26px);
	}
}
