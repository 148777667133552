/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-subscription-settings {
	@include flexbox-container(column);

	.sp-tab-bar {
		@include flexbox-item(column, 0, 0);
		margin-bottom: 1em;
		margin-right: 1em;
	}

	.sp-tab-bar-tab {
		margin: 1em 0 0 1em;
	}

	.sp-subscription-settings-tab {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column);
	}
}

.sp-subscription-settings-section-header {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column);
	@include font-t(6);
	line-height: 1.6;
	padding-bottom: 1em;
}

.sp-subscription-settings-section-header span {
	@include font-t(12);
	color: $color-vague-text;
	font-style: italic;
}

.sp-subscription-settings-section {
	@include flexbox-item(column, 0, 1);
	@include flexbox-container(column);
	padding-bottom: 1em;
}

.sp-subscription-settings-tab-notification-group-settings .sp-subscription-settings-section {
	@include flexbox-item(column, 1, 1);
}

.sp-subscription-settings-tab-notification-group-settings .sp-check-box-group-input {
	@include flexbox-item(column, 1, 0, 15em, 15em);
	overflow: auto;
}

.sp-subscription-settings-section p {
	@include font-t(7);
	line-height: 1.6;
}

.sp-subscription-settings-section-mute-settings > div {
	@include flexbox-container(row, nowrap, flex-start, flex-start);

	> div:first-child {
		@include flexbox-item(row, 0, 0);
	}

	> div:last-child {
		@include flexbox-item(row, 1, 0);
		@include flexbox-container(column);
		padding-left: .5em;
	}
}

.sp-subscription-settings-notification-check-box-text {
	@include flexbox-container(column);
	padding-bottom: .5em;
	padding-top: .25em;

	> div:first-child {
		@include font-t(7);
	}

	> div:last-child {
		@include font-t(12);
		font-style: italic;
	}
}
