/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-device-report-view-dialog {
	@include flexbox-item(column, 1, 1, 100%, 100%);
	@include flexbox-container(column);

	> .sp-device-report-view {
		@include flexbox-item(column, 1, 0);
		@include flexbox-container(column);

		> .sp-device-report-view-header {
			@include flexbox-item(column, 0, 0, 3em, 3em);
			@include flexbox-container(column, nowrap, center, center);
			@include font-t(2);
			position: relative;

			> img {
				height: 2.5em;
				left: 0;
				position: absolute;
				top: 0;
				width: auto;
			}
		}

		> .sp-device-report-view-rows {
			@include flexbox-item(column, 0, 0);
			@include flexbox-container(column);

			> .sp-device-report-view-row-header {
				@include flexbox-item(column, 0, 0, 2em, 2em);
				@include flexbox-container(column, nowrap, center, center);
				@include font-t(9);
				border-top: 1px solid $color-grey-40;

				& + .sp-device-report-view-row {
					border-top: 0;
				}
			}

			> .sp-device-report-view-row {
				@include flexbox-item(column, 0, 0);
				@include flexbox-container(row, wrap, space-between);
				border-top: 1px solid $color-grey-40;
				break-inside: avoid;
				page-break-inside: avoid;
				position: relative;

				> .sp-device-report-view-column {
					@include flexbox-item(row, 0, 0, calc(50% - .5em), calc(50% - .5em));

					> .sp-device-report-view-column-item {
						> .sp-device-report-view-column-item-description {
							@include flexbox-item(row, 1, 1, 39%, 39%);
						}

						> .sp-device-report-view-column-item-unit {
							@include flexbox-item(row, 1, 1, 12%, 12%);
						}

						> .sp-device-report-view-column-item-value {
							@include flexbox-item(row, 1, 1, 49%, 49%);
						}
					}
				}

				> .sp-device-report-view-single-column {
					@include flexbox-item(row, 0, 0, 100%, 100%);

					> .sp-device-report-view-column-item {
						// we keep the unit and value the same as above (but we scale to 1 single column without a margin)
						// and we give the description the rest of the space
						> .sp-device-report-view-column-item-description {
							@include flexbox-item(row, 1, 1, calc(100% - (100% - .5em)/2*.61), calc(100% - (100% - .5em)/2*.61));
						}

						> .sp-device-report-view-column-item-unit {
							@include flexbox-item(row, 1, 1, calc((100% - .5em)/2*.12), calc((100% - .5em)/2*.12));
						}

						> .sp-device-report-view-column-item-value {
							@include flexbox-item(row, 1, 1, calc((100% - .5em)/2*.49), calc((100% - .5em)/2*.49));
						}
					}
				}

				> .sp-device-report-view-column,
				> .sp-device-report-view-single-column {
					@include flexbox-container(column);
					padding-bottom: .5em;

					> .sp-device-report-view-column-header {
						@include flexbox-item(column, 0, 0, 2em, 2em);
						@include flexbox-container(column, nowrap, center);
						@include font-t(9);
					}

					> .sp-device-report-view-column-header:empty {
						@include flexbox-item(column, 0, 0, .5em, .5em);
					}

					> .sp-device-report-view-column-item {
						@include flexbox-item(column, 0, 0, 1.5em, 1.5em);
						@include flexbox-container(row, nowrap, space-between, center);
						border-bottom: 1px solid $color-grey-80;

						> .sp-device-report-view-column-item-description {
							@include font-t(7);
						}

						> .sp-device-report-view-column-item-unit {
							@include font-t(7);
						}

						> .sp-device-report-view-column-item-value {
							@include font-t(9);
							text-align: right;
						}
					}

					> .sp-device-report-view-column-item:last-child {
						border-bottom: 0;
					}
				}

			}
		}

		> .sp-device-report-view-footnotes {
			@include flexbox-item(column, 1, 0, auto, auto);
			@include flexbox-container(column, nowrap, flex-end, flex-start);

			> .sp-device-report-view-footnote {
				@include flexbox-item(column, 0, 0, 1em, 1em);
				@include font-t(12);
			}
		}
	}
}

@media screen {
	.sp-device-report-view-dialog {
		box-sizing: border-box;
		height: 279mm;
		margin: 0 auto;
		overflow: auto;
		padding: 5mm 20mm;
		width: 216mm;
	}
}
