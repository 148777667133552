/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-sites-and-segments {
	@include flexbox-container(column, nowrap, flex-start, stretch);

	> .sp-splitter {
		@include flexbox-item(column, 1, 0, 100%, 100%);

		> .sp-splitter-bar {
			margin-left: 2em;
			margin-right: 2em;
		}
	}
}
