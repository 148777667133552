/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-old-text-input-labels {
	display: block;
}

.sp-old-text-input-spacer {
	display: block;
	height: .25em;
}

.sp-old-text-input-controls {
	display: block;
}

.sp-old-text-input-input {
	width: 100%;
}
