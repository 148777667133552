/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-grid-filter {
	@include flexbox-container(column);

	> .sp-grid-filter-type {
		@include flexbox-item(column, 1, 0);
		@include flexbox-container(row);

		> .sp-combo-box-input {
			@include flexbox-item(row, 1, 0);

			&:first-child:not(:only-child) {
				@include flexbox-item(row, 0, 0);
				margin-right: .5em;
			}
		}
	}

	> div {
		@include flexbox-item(column, 1, 0);
		margin: .5em;
	}

	> .sp-grid-filter-alttext-div {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column);

		> .sp-grid-filter-alttext-span {
			@include flexbox-item(column, 1, 1);
			@include font-t(7);
			width: 15em;
		}
	}
}
