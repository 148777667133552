/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-update-historical-data-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	padding: 1em;

	> p {
		padding-bottom: 1em;
	}

	.sp-update-historical-data-dialog-date-time-input {
		@include flexbox-container(row, wrap, center, center, center);
		font-size: 1rem;

		> .sp-update-historical-data-dialog-date-time-input-label {
			@include flexbox-item(row, 1, 0);
			font-size: .875em;
		}

		> .sp-date-time-input {
			@include flexbox-item(row, 0, 0);
			margin-left: .25em;
		}
	}
}
