/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-radio-button-input {
	@include flexbox-container(column);

	> .sp-radio-button {
		@include flexbox-item(column, 0, 0);
	}

	&.sp-disabled > .sp-radio-button {
		border: 0;
		color: $input-color-disabled;
		padding: $input-border-width;
	}

	&.sp-disabled.sp-error > .sp-radio-button {
		color: $input-color-error-disabled;
	}

	&.sp-error > .sp-radio-button {
		color: $input-color-error;
	}

	&.sp-input-diff > .sp-radio-button {
		color: $color-blue-light;
	}

	&.sp-input-changed > .sp-radio-button {
		color: $color-blue;
	}
}
