/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-radio-button-group-input {
	@include flexbox-container(column);

	&.sp-radio-button-group-input-borders {
		@include border-radius($input-border-radius);
		border: $input-border-width solid $color-grey-50;
		box-sizing: border-box;
		padding: $input-border-focus-width - $input-border-width 0;
	}

	> .sp-radio-button {
		@include flexbox-item(column, 0, 0);
	}

	&.sp-disabled > .sp-radio-button {
		color: $input-color-disabled;

		&.sp-radio-button-group-input-borders {
			border: 0;
			padding: $input-border-width;
		}
	}

	&.sp-disabled.sp-error > .sp-radio-button {
		color: $input-color-error-disabled;
	}

	&.sp-error > .sp-radio-button {
		color: $input-color-error;
	}

	&.sp-input-diff > .sp-radio-button {
		color: $color-blue-light;
	}

	&.sp-input-changed > .sp-radio-button {
		color: $color-blue;
	}

	&.sp-radio-button-group-input-borders.sp-active {
		border-bottom-width: $input-border-focus-width;
		padding-bottom: 0;

		> .sp-radio-button:focus {
			@include border-radius(0);
			border-left: 0;
			border-right: 0;
			padding-left: $input-border-width;
			padding-right: $input-border-width;

			&:first-child {
				border-top: 0;
				padding-top: $input-border-width;
			}

			&:last-child {
				border-bottom: 0;
				padding-bottom: $input-border-width;
			}
		}
	}
}
