/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-virtualgrid {
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
	background-color: $background-color-content;
	box-sizing: border-box;
	height: 100%;
	max-width: 100%;
	overflow: hidden;

	&.sp-moving {
		> .sp-virtualgrid-header {
			> .sp-virtualgrid-headerrow {
				> .sp-virtualgrid-headercell {
					cursor: move;
				}
			}
		}

		> .sp-virtualgrid-body {
			> .sp-virtualgrid-row,
			> .sp-virtualgrid-unfetched,
			> .sp-virtualgrid-overflow {
				cursor: move;
			}
		}
	}

	&.sp-row-selection {
		> .sp-virtualgrid-header {
			> .sp-virtualgrid-headerrow {
				> .sp-virtualgrid-headercell {
					cursor: pointer;
				}
			}
		}

		> .sp-virtualgrid-body {
			> .sp-virtualgrid-row,
			> .sp-virtualgrid-unfetched,
			> .sp-virtualgrid-overflow {
				cursor: pointer;
			}
		}
	}
}

.sp-virtualgrid-header {
	@include flexbox-item(column, 0, 0);
	border-bottom: $inner-border;
	box-sizing: border-box;
	max-width: 100%;
	overflow: hidden;
}

.sp-virtualgrid-body {
	@include flexbox-item(column, 1, 1);
	border: 0;
	box-sizing: border-box;
	left: 0;
	max-width: 100%;
	min-width: 100%;
	// always show scrollbars to prevent having to deal with the resulting size differences
	overflow: scroll;
	// use relative position so that we can do voodoo on showing a subset of the rows
	position: relative;
	top: 0;
}

.sp-virtualgrid-row,
.sp-virtualgrid-headerrow {
	@include flexbox-container(row);
	box-sizing: border-box;
	break-inside: avoid;
	min-width: 100%;
	overflow: hidden;
	page-break-inside: avoid;
}

.sp-virtualgrid-row-placeholder {
	visibility: hidden;
}

.sp-virtualgrid-cell,
.sp-virtualgrid-headercell {
	@include flexbox-item(row, 0, 0);
	box-sizing: border-box;
	height: 100%; // needed so that child cells can be 100% height/width
	padding: 0 .5em; // same margin for all types of cells
	position: relative;
}

.sp-virtualgrid-cell {
	// Default font for all cell implementations.
	@include font-t(7);
	// Note that color is determined in .sp-virtualgrid-row
	overflow: hidden;
}

.sp-virtualgrid-headercell > .sp-virtualgridheadercell {
	height: 100%;
	width: 100%;
}

.sp-virtualgrid-headercell {
	// Default font for all header cell implementation
	@include font-t(9);
	color: $color-title-text;
}

.sp-virtualgrid-headercell-swap {
	background-color: $color-grey-80;

	> .sp-virtualgridheadercell {
		background-color: $color-grey-80;
	}
}

.sp-virtualgrid-drop {
	color: $color-accent-brand;
	font-size: 2em;
	height: 1em;
	position: absolute;
	text-align: center;
	top: -.25em;
	vertical-align: top;
	z-index: $z-index-1;
}

.sp-virtualgrid-drop-left {
	left: calc(-.5em - 1px);
}

.sp-virtualgrid-drop-right {
	right: -.5em;
}

.sp-virtualgrid-draghandle {
	cursor: ew-resize;
	display: inline-block;
	height: 100%;
	position: absolute;
	right: -5px;
	top: 0;
	width: 11px;
	z-index: $z-index-1;
}

// colors
.sp-virtualgrid-headerrow {
	background-color: $background-color-content;
	color: $color-text;
}

.sp-virtualgrid-row {
	background-color: $background-color-content;
	color: $color-text;
}

.sp-virtualgrid-row-vague {
	color: $color-vague-text;
	font-style: italic;
}

.sp-virtualgrid-row-even {
	background-color: $background-color-content-alternate;
}

.sp-virtualgrid-unfetched {
	background-color: $background-color-unfetched;
}

.sp-virtualgrid-row.sp-virtualgrid-selected {
	background-color: $background-color-selected;
	color: $color-selected-text;
}

.sp-virtualgrid-cell:not(:first-child),
.sp-virtualgrid-headercell:not(:first-child):not(:last-child) {
	border-left: $inner-border;
}

.sp-virtualgrid-cell:last-child,
.sp-virtualgrid-headercell:nth-last-child(2) {
	border-right: $inner-border;
}

.sp-virtualgrid-overflow {
	@include flexbox-container(row, nowrap, flex-start, center, center);
	background-color: $color-warn;
}

.sp-virtualgrid-overflow-text {
	@include flexbox-item(row, 0, 0);
	@include font-t(5);
	color: $color-selected-text;
	padding-left: .5em;
}

.sp-virtualgrid-cell-label-error {
	height: 0;
	overflow: hidden;
	padding-left: 1em;
	padding-top: 1em;
	position: absolute;
	right: 0;
	top: 0;
	width: 0;
}

.sp-virtualgrid-cell-label-error::after {
	border-left: 1em solid transparent;
	border-top: 1em solid $color-error;
	content: "";
	display: block;
	height: 0;
	margin-left: -1em;
	margin-top: -1em;
	width: 0;
}

.sp-virtualgrid-selected .sp-virtualgrid-cell-label-error::after {
	border-top-color: $color-grey-10;
}

.sp-virtualgrid-cell-label-warning {
	bottom: 0;
	height: 0;
	overflow: hidden;
	padding-top: 1em;
	position: absolute;
	right: 0;
	width: 1em;
}

.sp-virtualgrid-cell-label-warning::after {
	border-right: 1em solid $color-warn;
	border-top: 1em solid transparent;
	content: "";
	display: block;
	height: 0;
	margin-top: -1em;
	width: 0;
}

.sp-virtualgrid-selected .sp-virtualgrid-cell-label-warning::after {
	border-right-color: $color-grey-10;
}

.sp-virtualgrid-cell-label-diff {
	height: 0;
	left: 0;
	overflow: hidden;
	padding-right: .5em;
	padding-top: .5em;
	position: absolute;
	top: 0;
	width: 0;
}

.sp-virtualgrid-cell-label-diff::after {
	border-right: .5em solid transparent;
	border-top: .5em solid $color-diff;
	content: "";
	display: block;
	height: 0;
	margin-right: -.5em;
	margin-top: -.5em;
	width: 0;
}

.sp-virtualgrid-selected .sp-virtualgrid-cell-label-diff::after {
	border-left-color: $color-grey-10;
}

.sp-virtualgrid-header-dragged {
	background-color: $background-color-content;
	border: 0 !important;
	cursor: move;
	filter: alpha(opacity=60);
	margin: 0;
	opacity: .6;
	position: fixed;

	> sp-virtualgridheadercell > .sp-virtualgridheadercell-sort:hover {
		color: $color-text;
	}
}
