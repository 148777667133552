/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-dialog-section > .sp-dialog-columns {
	padding-top: .75em;

	> .sp-dialog-column > .sp-input-label {
		padding-bottom: .25em;
		padding-top: .25em;

		> .sp-input-label-labels {
			margin-right: 1em;
		}
	}
}
