/**
 * Copyright © 2017, ABB Switzerland Ltd.
 */

.sp-archive-record-report {
	@include flexbox-container(column);
}

.sp-archive-record-report-header {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column);
	@include font-t(2);
}

.sp-archive-record-report-rows {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column);
}

.sp-archive-record-report-row {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column);
	border-top: 1px solid $color-grey-40;
	padding: .5em 0;
}

.sp-archive-record-report-row-header {
	@include flexbox-item(column, 0, 0, 2em, 2em);
	@include flexbox-container(column, nowrap, center, center);
	@include font-t(9);
}

.sp-archive-record-report-columns {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(row);
}

.sp-archive-record-report-column {
	@include flexbox-item(row, 0, 0, 50%, 50%);
	@include flexbox-item(column, 0, 0);
	box-sizing: border-box;
	padding: 0 .5em;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	&:only-child {
		@include flexbox-item(row, 0, 0, 100%, 100%);
	}
}

.sp-archive-record-report-column-header {
	@include flexbox-item(column, 0, 0, 2em, 2em);
	@include flexbox-container(column, nowrap, center, center);
	@include font-t(9);
}

.sp-archive-record-report-items {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(column);
}

.sp-archive-record-report-item {
	@include flexbox-item(column, 0, 0);
	@include flexbox-container(row);
	border-bottom: 1px solid $color-grey-80;
	line-height: 1.75;

	&:last-child {
		border-bottom: 0;
	}
}

.sp-archive-record-report-item-name {
	@include flexbox-item(row, 0, 0, 43%, 43%);
	@include font-t(7);
}

.sp-archive-record-report-column:only-child .sp-archive-record-report-item-name {
	@include flexbox-item(row, 0, 0, 72%, 72%);
}

.sp-archive-record-report-item-changed .sp-archive-record-report-item-name::after {
	@include font-t(12);
	content: " †";
	font-size: 80%;
	vertical-align: top;
}

.sp-archive-record-report-item-unit {
	@include flexbox-item(row, 0, 0, 11%, 11%);
	@include font-t(7);
	white-space: nowrap;
}

.sp-archive-record-report-column:only-child .sp-archive-record-report-item-unit {
	@include flexbox-item(row, 0, 0, 6%, 6%);
}

.sp-archive-record-report-item-value {
	@include flexbox-item(row, 0, 0, 46%, 46%);
	@include font-t(9);
	text-align: right;
}

.sp-archive-record-report-column:only-child .sp-archive-record-report-item-value {
	@include flexbox-item(row, 0, 0, 22%, 22%);
}

.sp-archive-record-report-footer {
	@include flexbox-item(column, 1, 0);
	@include flexbox-container(column, nowrap, flex-end);
}

.sp-archive-record-report-changed-footer {
	@include flexbox-item(column, 0, 0);
	@include font-t(12);
	line-height: 1.75;
}
