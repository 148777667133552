/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */


.sp-navigation-tabs {
	@include flexbox-container(column, nowrap);

	> .sp-navigation-tabs-inner {
		@include flexbox-item(column, 1, 0, 100%, 100%);
		@include flexbox-container(column, nowrap);

		> .sp-tab-bar {
			@include flexbox-item(column, 0, 0);
		}

		> .sp-navigation-tab-content {
			@include flexbox-item(column, 1, 1, 100%, 100%);
			@include flexbox-container(column);
			overflow-y: auto;

			> :first-child {
				@include flexbox-item(column, 1, 1, 100%, 100%);
			}
		}
	}
}

.sp-content-body > .sp-navigation-tabs {
	@include flexbox-item(column, 0, 0, 100%, 100%);
}
