/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-old-input-labels {
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
	padding: .5em 0;

	> .sp-old-input-label-labels {
		@include flexbox-item(column, 0, 0, auto, auto, auto);
		padding-bottom: .5em;

		> .sp-old-input-label-text:nth-child(1n+2) {
			padding-left: 1em;
		}
	}

	> .sp-old-input-label-inputs {
		@include flexbox-item(column, 0, 0, auto, auto, auto);
	}

	&.sp-disabled > .sp-old-input-label-labels > .sp-old-input-label-text {
		color: $input-label-color-disabled;

		&.sp-old-input-label-text-error {
			color: $input-label-color-error-disabled;
		}
	}
}
