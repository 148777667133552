/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-dialog-columns {
	@include flexbox-container(row, wrap, space-between, stretch, flex-start);
	background-color: $background-color-content;
	margin: .5em 1em;

	> .sp-dialog-column {
		@include flexbox-item(row, 0, 1, 100%);
	}
}

.sp-dialog-column {
	@include flexbox-container(column, nowrap, stretch, stretch, flex-start);
	overflow: hidden;
}
