/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$header-height: 2em;
$mainmenuitem-height: 2em;
$mainmenuitem-icon-width: 2em;
$mainmenuitem-indent-width: 0;

.sp-mainmenu {
	@include flexbox-container(column, nowrap);
	background-color: $background-color-mainmenu;
	overflow: auto;
}

.sp-navigation-structure {
	@include flexbox-item(column, 0, 0, $mainmenuitem-height, $mainmenuitem-height);
	@include flexbox-container(row, nowrap, flex-start, center);
	cursor: pointer;
}

@for $i from 1 through length($mainmenu-level-colors) {
	$mainmenu-level-color: nth($mainmenu-level-colors, $i);

	.sp-mainmenu-level#{$i} {
		.sp-mainmenu-icondiv {
			background-color: $mainmenu-level-color;
		}
	}

	.sp-mainmenu-level#{$i}.sp-collapsed {
		background-color: $mainmenu-level-color;
	}

	.sp-mainmenu-hover-level#{$i}.sp-collapsed:hover {
		background-color: $mainmenu-level-color;
	}
}

.sp-mainmenu-level1 {
	border-bottom: 2px solid $border-color-mainmenu;
}

.sp-mainmenu-icondiv {
	// stretch ourselves vertically
	@include flexbox-item(row, 0, 0, $mainmenuitem-icon-width, $mainmenuitem-icon-width, stretch);

	// center text hor and vert
	@include flexbox-container(column, nowrap, center, center, center);
	text-align: center;

	.sp-icon {
		color: $color-mainmenu-text;
		font-size: 1.5em;
	}
}

.sp-mainmenu-text {
	@include flexbox-item(row, 1, 0, initial, auto);
	@include font-t(7);
	color: $color-mainmenu-text;
	margin-left: 1em;
}
