/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-grid-filter-bar {
	@include flexbox-container(column);
	border-bottom: 2px solid $inner-border-color;

	> .sp-grid-filters {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(row, wrap);
		margin: 0 .5em .5em 0;

		> .sp-grid-filters-filter,
		> .sp-grid-filters-filter-button {
			@include flexbox-item(row, 0, 0);
			height: 2em;
			margin: .5em 0 0 .5em;
		}

		> .sp-grid-filters-filter-button {
			@include flexbox-container(row);

			> .sp-button {
				> .sp-button-icon {
					padding-left: 0;
				}

				> .sp-button-text {
					padding-right: .5em;
				}
			}
		}

		> .sp-grid-filters-filter {
			@include flexbox-container(row, nowrap, space-between, center);
			background-color: $color-grey-80;

			> .sp-check-box {
				@include flexbox-item(row, 0, 0, 2em, 2em);

				> label {
					@include flexbox-container(row, nowrap, center, center);
				}
			}

			> .sp-grid-filters-filter-name {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row, nowrap, center, baseline);

				> span:nth-child(1) {
					@include font-t(7);
				}

				> span:nth-child(2) {
					@include font-t(12);
					color: $color-accent-9;
					margin-left: .5em;
				}
			}

			> .sp-button {
				@include flexbox-item(row, 0, 0, 2em, 2em);
				background: none;

				> .sp-button-icon > .sp-icon {
					font-size: 1em;
				}
			}

			&.sp-grid-filter-editable {
				cursor: pointer;
			}

			&.sp-active {
				background-color: $color-accent-9;

				> .sp-check-box {
					color: $color-white;
				}

				> .sp-grid-filters-filter-name {
					color: $color-white;

					> span:nth-child(2) {
						color: $color-white;
					}
				}

				> .sp-button {
					background: none;
					color: $color-white;
				}
			}

			&.sp-invalid {
				background-color: $color-error;

				> .sp-check-box {
					color: $color-white;
				}

				> .sp-grid-filters-filter-name {
					color: $color-white;

					> span:nth-child(2) {
						color: $color-white;
					}
				}

				> .sp-button {
					background: none;
					color: $color-white;
				}
			}
		}

		> .sp-grid-filters-filter.sp-grid-filters-add-button {
			background-color: $button-dark-background-color;

			> .sp-combo-box-input {
				@include flexbox-item(row, 0, 0, 10em, 10em);
				margin-right: .25em;
			}

			> .sp-grid-filters-add-button-icon {
				@include flexbox-item(row, 0, 0, 2em, 2em);
				@include flexbox-container(column, nowrap, center, center);
				background-color: $button-dark-background-color;
				color: $button-dark-foreground-color;
				padding: 0;

				> .sp-icon {
					font-size: 1.5em;
				}
			}
		}
	}

	.sp-overlay {
		> .sp-grid-filter-overlay {
			@include box-shadow($default-box-shadow);
			@include flexbox-container(column);
			background-color: $background-color-content;
			cursor: default;
			margin: .25em 0;
			pointer-events: all;

			> .sp-grid-filter-overlay-header {
				@include flexbox-item(column, 1, 0, 2em, 2em);
				@include flexbox-container(row, nowrap, flex-start, center);
				background: $color-grey-30;
				padding: 0 1em;

				> span {
					@include font-t(7);
					color: $color-white;
				}
			}

			&.sp-grid-filter-edit-overlay {

				> .sp-grid-filter-edit-overlay-filter {
					@include flexbox-item(column, 1, 0);
					@include flexbox-container(column);

					> .sp-grid-filter {
						@include flexbox-item(column, 1, 0);
					}
				}
			}

			&.sp-grid-filter-delete-overlay {
				@include flexbox-container(row, nowrap, center, center);
				background: $color-grey-30;
				color: $color-white;

				> span {
					@include flexbox-item(row, 1, 0);
					@include font-t(7);
					padding: 0 .5em 0 1em;
					text-align: center;
				}

				> .sp-button {
					@include flexbox-item(row, 0, 0, 2em, 2em);
					height: 2em;
				}
			}
		}
	}
}
