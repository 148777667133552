/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-measurement-dashboard-segment-overview {
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);
	margin: 2em 0;
	position: relative;

	> .sp-loading-indicator {
		@include flexbox-item(column, 1, 0);
	}

	> .sp-measurement-dashboard-segment-overview-heading {
		@include flexbox-item(column, 0, 0, 2em, 2em);
		@include flexbox-container(row);
		border-bottom: 2px solid $inner-border-color;
		border-top: 2px solid $inner-border-color;
		cursor: pointer;
		margin: 0 2em;

		> .sp-measurement-dashboard-segment-overview-heading-content {
			@include flexbox-item(row, 1, 0);
			@include flexbox-container(row, nowrap, center, stretch, stretch);
			padding-left: 2em;

			> .sp-measurement-dashboard-segment-overview-segment-name {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row, nowrap, flex-start, center);
				margin-left: 1em;

				> span:first-child {
					@include font-t(3);
					padding-right: .25em;
				}

				> span:last-child {
					@include font-t(4);
				}
			}

			> .sp-measurement-dashboard-segment-overview-segment-tag {
				@include flexbox-item(row, 0, 0);
				@include flexbox-container(row, nowrap, flex-start, center);
				margin-left: 1em;

				&:last-child {
					margin-right: 1em;
				}

				> span {
					@include flexbox-item(row, 0, 0);
					@include flexbox-container(row, nowrap, flex-start, center);

					&:first-child > .sp-icon {
						font-size: 1.5em;
					}

					&:last-child {
						@include font-t(3);
					}
				}

				&.sp-error > span {
					color: $color-error;
				}

				&.sp-disabled > span {
					color: $color-disabled-text;
				}
			}
		}

		> .sp-measurement-dashboard-segment-overview-heading-toggle {
			@include flexbox-item(row, 0, 0, 2em, 2em);
			@include flexbox-container(column);

			> .sp-button {
				@include flexbox-item(column, 1, 0);
				background: none;
			}
		}
	}

	> .sp-measurement-dashboard-segment-overview-segment-menu {
		@include flexbox-container(column);
		@include box-shadow($default-box-shadow);
		background-color: $background-color-content;
		left: 2em;
		position: absolute;
		right: 2em;
		top: calc(2em + 6px);

		> .sp-measurement-dashboard-segment-overview-segment-menu-item {
			@include flexbox-item(column, 0, 0, 2em, 2em);
			@include flexbox-container(row, nowrap, center, center);
			cursor: pointer;

			> span:first-child {
				@include font-t(7);
				padding-right: .25em;
			}

			> span:last-child {
				@include font-t(9);
			}

			&.sp-active {
				background-color: $color-accent-brand;
				color: $color-white;
			}

			&:hover {
				background-color: $color-accent-brand-dark;
				color: $color-white;
			}
		}
	}

	> .sp-measurement-dashboard-segment-overview-segment-devices {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row, wrap, flex-start, stretch, stretch);
		margin: 1em;

		> .sp-panel {
			@include flexbox-item(row, 0, 0, 20em, 20em);
			margin: 1em;

			> .sp-panel-header {
				@include flexbox-container(column);

				> .sp-measurement-dashboard-segment-overview-segment-device-header {
					@include flexbox-item(column, 1, 0);
					@include flexbox-container(row);

					> .sp-measurement-dashboard-segment-overview-segment-device-header-content {
						@include flexbox-item(row, 1, 0);
						@include flexbox-container(row);

						> .sp-measurement-dashboard-segment-overview-segment-device-name {
							@include flexbox-item(row, 0, 0);
							@include flexbox-container(column, nowrap, center);
							margin-left: 1em;
						}

						> .sp-measurement-dashboard-segment-overview-segment-device-alarms {
							@include flexbox-item(row, 0, 0);
							@include flexbox-container(row, nowrap, flex-start, center);
							margin-left: 1em;

							> .sp-icon {
								font-size: 1.5em;
							}

							&.sp-error > span {
								color: $color-error;
							}

							&.sp-disabled > span {
								color: $color-disabled-text;
							}
						}
					}

					> .sp-measurement-dashboard-segment-overview-segment-device-header-button {
						@include flexbox-item(row, 0, 0);
						@include flexbox-container(column);

						> .sp-button {
							@include flexbox-item(column, 1, 0);
							margin-left: 1em;
						}
					}
				}
			}

			> .sp-panel-body {
				@include flexbox-container(column, nowrap, flex-start, stretch, stretch);

				> .sp-measurement-dashboard-segment-overview-segment-device-overall {
					@include flexbox-item(column, 1, 0);
					@include flexbox-container(row);
					padding: .5em 1em;

					> .sp-measurement-dashboard-segment-overview-segment-device-icon {
						@include flexbox-item(row, 0, 0, 4em, 4em);
						@include flexbox-container(column, nowrap, center, center);

						> .sp-icon {
							font-size: 4em;
						}
					}

					> .sp-measurement-dashboard-segment-overview-segment-device-overall-message {
						@include flexbox-item(row, 1, 1);
						@include flexbox-container(column, nowrap, center, stretch, stretch);
						padding: 0 1em;

						// ensure line wraps in IE
						width: 100%;

						> span {
							width: 100%;
						}

						> span:first-child {
							@include font-t(8);
							padding-bottom: .5em;
						}

						> span:last-child {
							@include font-t(7);
						}
					}
				}

				> .sp-measurement-dashboard-segment-overview-segment-device-tags {
					@include flexbox-item(column, 1, 0);
					@include flexbox-container(column);
					padding: .5em 0;

					> .sp-measurement-dashboard-segment-overview-segment-device-tag {
						@include flexbox-item(column, 0, 0, 1.5em, 1.5em);
						@include flexbox-container(row);

						> .sp-measurement-dashboard-segment-overview-segment-device-tag-text {
							@include flexbox-item(row, 0, 0, 8em, 8em);
							@include flexbox-container(column, nowrap, center);
							padding-left: 1em;
							padding-right: 1em;

							> span {
								@include font-t(7);
								color: $color-text;
							}
						}

						> .sp-measurement-dashboard-segment-overview-segment-device-tag-error {
							@include flexbox-item(row, 0, 0, 2em, 2em);
							@include flexbox-container(column, nowrap, center, center);

							> .sp-icon {
								color: $color-error;
								font-size: 1.5em;
							}
						}

						> .sp-measurement-dashboard-segment-overview-segment-device-tag-value {
							@include flexbox-item(row, 0, 0, 4em, 4em);
							@include flexbox-container(column, nowrap, center);
							padding-right: 1em;
							text-align: right;

							> span {
								@include font-t(8);
								color: $color-text;
							}
						}

						> .sp-measurement-dashboard-segment-overview-segment-device-tag-unit {
							@include flexbox-item(row, 0, 0, 2em, 2em);
							@include flexbox-container(column, nowrap, center);
							padding-right: 1em;

							> span {
								@include font-t(10);
								color: $color-unimportant-text;
							}
						}
					}
				}
			}
		}
	}
}
