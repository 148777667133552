/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-grid-cell-boolean-text {
	@include flexbox-container(row, nowrap, center, center, center);
	height: 100%;
	white-space: nowrap;
	width: 100%;
}

.sp-grid-cell-boolean-text-value {
	@include flexbox-item(row, 0, 0);
	font-size: 1.5em;
}
