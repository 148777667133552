/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-current-user {
	@include flexbox-container(row, nowrap);

	> .sp-button {
		@include flexbox-item(row, 0, 0);
	}
}
