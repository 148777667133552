/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-notauthorized {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column, nowrap, flex-start, flex-start, center);
}

.sp-notauthorized-text {
	@include flexbox-item(column, 0, 0);
	@include font-t(5);
	color: $color-text;
	margin-left: 2em;
	margin-top: 2em;
}
