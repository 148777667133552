/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-search-input.sp-empty {
	background-image: url("/core/static/images/search.png");
	background-position: left center;
	background-repeat: no-repeat;
	outline: 0;
}
