/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-content-body > .sp-poller-dashboard {
	margin-left: 2em;
	margin-top: 2em;
}

.sp-poller-dashboard {
	@include flexbox-container(row, wrap, center, flex-start, flex-start);

	> .sp-panel {
		@include flexbox-item(row, 0, 0);
		margin-bottom: 2em;
		margin-right: 2em;

		> .sp-panel-body {
			> :first-child {
				@include flexbox-item(column, 1, 1, 10em, 10em);
				width: 20em;
			}
		}
	}

	.sp-poller-dashboard-block-failures {
		@include flexbox-container(row);

		.sp-poller-dashboard-failure {
			@include flexbox-item(row, 1, 1);
			@include flexbox-container(column, nowrap, space-around, center);

			.sp-icon {
				@include flexbox-item(column, 0, 0);
				font-size: 3em;
				margin-top: .2em;
			}

			.sp-poller-dashboard-failure-text {
				@include flexbox-item(column, 0, 0);
				@include font-t(7);
				color: $color-text;
			}

			.sp-poller-dashboard-failure-count {
				@include flexbox-item(column, 0, 0);
				@include font-t(1);
				color: $color-text;
				margin-bottom: .5em;
			}

			.sp-disabled {
				color: $color-disabled-text;
				cursor: default;
			}

			.sp-error {
				color: $color-error;
			}
		}
	}

	.sp-poller-dashboard-block-resources {
		@include flexbox-container(row);

		.sp-poller-dashboard-pgagerow {
			@include flexbox-item(row, 1, 1);
			@include flexbox-container(column, nowrap, center, center);

			.sp-poller-dashboard-pgagerow-value {
				@include flexbox-item(column, 0, 0);
				@include font-t(7);
				color: $color-text;
			}

			.sp-percentagegauge {
				@include flexbox-item(column, 0, 0, 5em, 5em);
				margin-bottom: 1em;
				margin-top: 1em;
				width: 5em;
			}

			.sp-poller-dashboard-pgagerow-title {
				@include flexbox-item(column, 0, 0);
				@include font-t(7);
				color: $color-text;
			}
		}
	}

	.sp-poller-dashboard-block-table {
		@include flexbox-container(column);

		.sp-dashtable-row {
			@include flexbox-item(column, 0, 1, 1.1em, 1.1em);
			@include flexbox-container(row, nowrap, space-around, center);
			margin-top: .2em;

			.sp-dashtable-cell {
				@include flexbox-container(column, nowrap, flex-end, center, center);
				height: 1.1em;
			}

			.sp-dashtable-cell:last-child {
				margin-right: 1em;
			}

			.sp-dashtable-cell:first-child {
				@include flexbox-item(row, 0, 1, 2em, 2em);
				margin-left: 1em;
			}

			.sp-dashtable-cell:not(:first-child) {
				@include flexbox-item(row, 0, 1, 4em, 4em);

			}

			.sp-dashtable-text-header,
			.sp-dashtable-text {
				text-align: right;
				width: 100%;
			}

			.sp-dashtable-text-header {
				@include font-t(10);
				color: $color-text;
			}

			.sp-dashtable-text {
				@include font-t(9);
				color: $color-text;
			}
		}

		.sp-dashtable-row:last-child {
			margin-bottom: .2em;
		}
	}

	.sp-poller-dashboard-block-job {
		@include flexbox-container(column);

		.sp-dashjob-row {
			@include flexbox-item(column, 1, 1);
		}

		.sp-dashjob-periodrow {
			@include flexbox-container(column, nowrap, center, center, center);
			// center text hor and vert
			text-align: center;
		}

		.sp-dashjob-period-text {
			@include font-t(10);
			color: $color-text;
			max-width: 96%;
		}

		.sp-dashjob-progressrow {
			@include flexbox-container(column, nowrap, center, center, center);
			@include font-t(9);
			color: $color-text;
			// center text hor and vert
			text-align: center;
		}

		.sp-dashjob-buttonrow {
			@include flexbox-container(row, nowrap, space-around);
		}

		.sp-button {
			@include flexbox-item(row, 0, 0, 2em, 2em);
			height: 2em;
			width: 2em;
		}
	}

	.sp-poller-dashboard-block-list {
		@include flexbox-container(column);

		.sp-dashlist-row {
			@include flexbox-item(column, 0, 1, 1em, 1em);
			@include flexbox-container(row, nowrap, stretch, stretch);

			margin-top: .2em;

			.sp-dashlist-cell {
				height: 1.1em;
			}

			.sp-dashlist-cell:first-child {
				@include flexbox-item(row, 0, 0, 10em, 10em);
				margin-left: 1em;
			}

			.sp-dashlist-cell:last-child {
				@include flexbox-item(row, 1, 1);
				margin-right: 1em;
			}

			.sp-dashlist-title {
				@include font-t(7);
				color: $color-text;
			}

			.sp-dashlist-value {
				@include font-t(9);
				color: $color-text;
			}

			.sp-dashlist-cell.sp-error {
				color: $color-error;
			}

			.sp-dashlist-cell.sp-warn {
				color: $color-warn;
			}
		}

		.sp-dashlist-row:first-child {
			margin-top: .5em;
		}
	}
}
