/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

$actionbar-action-height: 2em;
$actionbar-group-gap: 2px;

.sp-actionbar {
	@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
	background-color: $background-color-header-20;
	height: $actionbar-action-height;
	overflow: hidden;
	width: 100%;

	> .sp-actionbar-titlediv {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(row, nowrap, center, center, center);
		overflow: hidden;

		> .sp-actionbar-title {
			@include flexbox-item(row, 0, 0);
			@include font-t(5);
			color: $color-text;
			margin: .5em;
		}
	}

	> .sp-actionbar-actions {
		@include flexbox-item(row, 1, 1);
		@include flexbox-container(row, wrap, flex-end, stretch, stretch);
		max-height: $actionbar-action-height;
		overflow: hidden;

		> .sp-actionbar-group {
			@include flexbox-item(row, 0, 0);
			@include flexbox-container(row, nowrap, stretch, stretch, stretch);

			> .sp-button {
				@include flexbox-item(row, 0, 0);
			}
		}

		.sp-actionbar-group + .sp-actionbar-group {
			margin-left: $actionbar-group-gap;
		}
	}

	> .sp-actionbar-menudiv {
		@include flexbox-item(row, 0, 0, $actionbar-action-height, $actionbar-action-height);
		@include flexbox-container(row, wrap, stretch, stretch, stretch);
		height: $actionbar-action-height;
		margin-left: $actionbar-group-gap;

		> .sp-button {
			@include flexbox-item(row, 0, 0);
		}
	}
}

@media print  {
	.sp-actionbar {
		background-color: transparent;

		> .sp-actionbar-actions,
		> .sp-actionbar-menudiv {
			display: none;
		}
	}
}
