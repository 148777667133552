@font-face {
  font-family: 'exlent';
  src: url('/core/static/font-exlent/exlent.eot?17295533');
  src: url('/core/static/font-exlent/exlent.eot?17295533#iefix') format('embedded-opentype'),
       url('/core/static/font-exlent/exlent.woff2?17295533') format('woff2'),
       url('/core/static/font-exlent/exlent.woff?17295533') format('woff'),
       url('/core/static/font-exlent/exlent.ttf?17295533') format('truetype'),
       url('/core/static/font-exlent/exlent.svg?17295533#exlent') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'exlent';
    src: url('/core/static/font-exlent/exlent.svg?17295533#exlent') format('svg');
  }
}
*/

.xl-icon-filter:before { content: '\e800'; } /* '' */
.xl-icon-home:before { content: '\e801'; } /* '' */
.xl-icon-hot:before { content: '\e802'; } /* '' */
.xl-icon-info:before { content: '\e803'; } /* '' */
.xl-icon-jobs-interrupt:before { content: '\e804'; } /* '' */
.xl-icon-links:before { content: '\e805'; } /* '' */
.xl-icon-jobs-running:before { content: '\e806'; } /* '' */
.xl-icon-link-reconnect:before { content: '\e807'; } /* '' */
.xl-icon-db-connections:before { content: '\e808'; } /* '' */
.xl-icon-link-warning:before { content: '\e809'; } /* '' */
.xl-icon-location:before { content: '\e80a'; } /* '' */
.xl-icon-down-open:before { content: '\e80b'; } /* '' */
.xl-icon-right-open:before { content: '\e80c'; } /* '' */
.xl-icon-left-open:before { content: '\e80d'; } /* '' */
.xl-icon-up-open:before { content: '\e80e'; } /* '' */
.xl-icon-search:before { content: '\e80f'; } /* '' */
.xl-icon-ok:before { content: '\e810'; } /* '' */
.xl-icon-pause:before { content: '\e811'; } /* '' */
.xl-icon-sort:before { content: '\e812'; } /* '' */
.xl-icon-print:before { content: '\e813'; } /* '' */
.xl-icon-radio-off:before { content: '\e814'; } /* '' */
.xl-icon-radio-on:before { content: '\e815'; } /* '' */
.xl-icon-reboot:before { content: '\e816'; } /* '' */
.xl-icon-edit:before { content: '\e817'; } /* '' */
.xl-icon-shutdown:before { content: '\e818'; } /* '' */
.xl-icon-stop:before { content: '\e819'; } /* '' */
.xl-icon-sort-up:before { content: '\e81a'; } /* '' */
.xl-icon-sort-down:before { content: '\e81b'; } /* '' */
.xl-icon-master:before { content: '\e81c'; } /* '' */
.xl-icon-warm:before { content: '\e81d'; } /* '' */
.xl-icon-play:before { content: '\e81e'; } /* '' */
.xl-icon-time:before { content: '\e81f'; } /* '' */
.xl-icon-unknown:before { content: '\e820'; } /* '' */
.xl-icon-new:before { content: '\e821'; } /* '' */
.xl-icon-warning:before { content: '\e822'; } /* '' */
.xl-icon-left-dir:before { content: '\e823'; } /* '' */
.xl-icon-right-dir:before { content: '\e824'; } /* '' */
.xl-icon-cancel:before { content: '\e825'; } /* '' */
.xl-icon-unchecked:before { content: '\e826'; } /* '' */
.xl-icon-semi-checked:before { content: '\e827'; } /* '' */
.xl-icon-checked:before { content: '\e828'; } /* '' */
.xl-icon-minus:before { content: '\e829'; } /* '' */
.xl-icon-connection:before { content: '\e82a'; } /* '' */
.xl-icon-database:before { content: '\e82b'; } /* '' */
.xl-icon-delete:before { content: '\e82c'; } /* '' */
.xl-icon-download:before { content: '\e82d'; } /* '' */
.xl-icon-config-check:before { content: '\e82e'; } /* '' */
.xl-icon-failed:before { content: '\e82f'; } /* '' */
.xl-icon-db-failed:before { content: '\e830'; } /* '' */
.xl-icon-boolean-neutral:before { content: '\e831'; } /* '' */
.xl-icon-boolean-true:before { content: '\e832'; } /* '' */
.xl-icon-boolean-false:before { content: '\e833'; } /* '' */
.xl-icon-performance:before { content: '\e834'; } /* '' */
.xl-icon-un-hide:before { content: '\e835'; } /* '' */
.xl-icon-alarm:before { content: '\e836'; } /* '' */
.xl-icon-analog:before { content: '\e837'; } /* '' */
.xl-icon-archive:before { content: '\e838'; } /* '' */
.xl-icon-network:before { content: '\e839'; } /* '' */
.xl-icon-pressure:before { content: '\e83a'; } /* '' */
.xl-icon-pulse:before { content: '\e83b'; } /* '' */
.xl-icon-coriolis:before { content: '\e83c'; } /* '' */
.xl-icon-jobs-failed:before { content: '\e83d'; } /* '' */
.xl-icon-poller:before { content: '\e83e'; } /* '' */
.xl-icon-ultrasonic:before { content: '\e83f'; } /* '' */
.xl-icon-device:before { content: '\e840'; } /* '' */
.xl-icon-device-failed:before { content: '\e841'; } /* '' */
.xl-icon-device-warning:before { content: '\e842'; } /* '' */
.xl-icon-diagnostics:before { content: '\e843'; } /* '' */
.xl-icon-digital:before { content: '\e844'; } /* '' */
.xl-icon-etl:before { content: '\e845'; } /* '' */
.xl-icon-archives:before { content: '\e846'; } /* '' */
.xl-icon-calibration:before { content: '\e847'; } /* '' */
.xl-icon-versions:before { content: '\e848'; } /* '' */
.xl-icon-detail:before { content: '\e849'; } /* '' */
.xl-icon-column:before { content: '\e84a'; } /* '' */
.xl-icon-row:before { content: '\e84b'; } /* '' */
.xl-icon-first:before { content: '\e84c'; } /* '' */
.xl-icon-last:before { content: '\e84d'; } /* '' */
.xl-icon-filter-clear:before { content: '\e84e'; } /* '' */
.xl-icon-mail:before { content: '\e84f'; } /* '' */
.xl-icon-switch-user:before { content: '\e850'; } /* '' */
.xl-icon-security:before { content: '\e851'; } /* '' */
.xl-icon-log-out:before { content: '\e852'; } /* '' */
.xl-icon-log-in:before { content: '\e853'; } /* '' */
.xl-icon-send-password:before { content: '\e854'; } /* '' */
.xl-icon-reset-password:before { content: '\e855'; } /* '' */
.xl-icon-settings:before { content: '\e856'; } /* '' */
.xl-icon-ticket:before { content: '\e857'; } /* '' */
.xl-icon-users:before { content: '\e858'; } /* '' */
.xl-icon-user:before { content: '\e859'; } /* '' */
.xl-icon-default:before { content: '\e85a'; } /* '' */
.xl-icon-groups:before { content: '\e85b'; } /* '' */
.xl-icon-customer:before { content: '\e85c'; } /* '' */
.xl-icon-log:before { content: '\e85d'; } /* '' */
.xl-icon-menu:before { content: '\e85e'; } /* '' */
.xl-icon-overflow:before { content: '\e85f'; } /* '' */
.xl-icon-permissions:before { content: '\e860'; } /* '' */
.xl-icon-report:before { content: '\e861'; } /* '' */
.xl-icon-iv-rate:before { content: '\e862'; } /* '' */
.xl-icon-roles:before { content: '\e863'; } /* '' */
.xl-icon-question:before { content: '\e864'; } /* '' */
.xl-icon-download-report:before { content: '\e865'; } /* '' */
.xl-icon-collapse-all:before { content: '\e866'; } /* '' */
.xl-icon-expand-all:before { content: '\e867'; } /* '' */
.xl-icon-truck:before { content: '\e868'; } /* '' */
.xl-icon-connected:before { content: '\e869'; } /* '' */
.xl-icon-disconnected:before { content: '\e86a'; } /* '' */
.xl-icon-alarm-notifications:before { content: '\e86b'; } /* '' */
.xl-icon-reports:before { content: '\e86c'; } /* '' */
.xl-icon-reload:before { content: '\e86d'; } /* '' */
.xl-icon-reload-add:before { content: '\e86e'; } /* '' */
.xl-icon-refresh:before { content: '\e86f'; } /* '' */
.xl-icon-mailing-list:before { content: '\e870'; } /* '' */
.xl-icon-day:before { content: '\e871'; } /* '' */
.xl-icon-recalculate:before { content: '\e872'; } /* '' */
.xl-icon-month:before { content: '\e873'; } /* '' */
.xl-icon-devices:before { content: '\e874'; } /* '' */
.xl-icon-clear:before { content: '\e875'; } /* '' */
.xl-icon-apply:before { content: '\e876'; } /* '' */
.xl-icon-totals:before { content: '\e877'; } /* '' */
.xl-icon-no-flow:before { content: '\e878'; } /* '' */
.xl-icon-flow:before { content: '\e879'; } /* '' */
.xl-icon-quorum:before { content: '\e87a'; } /* '' */
.xl-icon-alarms:before { content: '\e87b'; } /* '' */
.xl-icon-viscosity:before { content: '\e87c'; } /* '' */
.xl-icon-bsw:before { content: '\e87d'; } /* '' */
.xl-icon-temperature:before { content: '\e87e'; } /* '' */
.xl-icon-density:before { content: '\e87f'; } /* '' */
.xl-icon-gravity:before { content: '\e880'; } /* '' */
.xl-icon-exlent-event-log:before { content: '\e881'; } /* '' */
.xl-icon-copy-report:before { content: '\e882'; } /* '' */
.xl-icon-density-temperature:before { content: '\e883'; } /* '' */
.xl-icon-density-pressure:before { content: '\e884'; } /* '' */
.xl-icon-measurement-dashboard:before { content: '\e885'; } /* '' */
.xl-icon-overviews:before { content: '\e886'; } /* '' */
.xl-icon-device-event-log:before { content: '\e887'; } /* '' */
.xl-icon-spin5:before { content: '\e888'; } /* '' */
.xl-icon-alarm-settings:before { content: '\e889'; } /* '' */
.xl-icon-report-settings:before { content: '\e88a'; } /* '' */
.xl-icon-alarm-active:before { content: '\e88b'; } /* '' */
.xl-icon-logs:before { content: '\e88c'; } /* '' */
.xl-icon-copy-archive:before { content: '\e88d'; } /* '' */
.xl-icon-upload:before { content: '\e88e'; } /* '' */
.xl-icon-exlent:before { content: '\e88f'; } /* '' */
.xl-icon-report-history:before { content: '\e890'; } /* '' */
.xl-icon-segments:before { content: '\e891'; } /* '' */
.xl-icon-sites:before { content: '\e892'; } /* '' */
.xl-icon-real-time-values:before { content: '\e893'; } /* '' */
.xl-icon-reset:before { content: '\e894'; } /* '' */
.xl-icon-radio-semi:before { content: '\e895'; } /* '' */
.xl-icon-contract:before { content: '\e8a5'; } /* '' */
.xl-icon-endpoint:before { content: '\e8a6'; } /* '' */
.xl-icon-nomination:before { content: '\e8a7'; } /* '' */
.xl-icon-generate-report:before { content: '\e929'; } /* '' */
.xl-icon-revert:before { content: '\e935'; } /* '' */
.xl-icon-batch:before { content: '\e936'; } /* '' */
.xl-icon-down:before { content: '\e944'; } /* '' */
.xl-icon-out:before { content: '\e945'; } /* '' */
.xl-icon-up:before { content: '\e946'; } /* '' */
.xl-icon-in:before { content: '\e947'; } /* '' */
.xl-icon-unlock:before { content: '\e94d'; } /* '' */
.xl-icon-redundancy:before { content: '\e951'; } /* '' */
.xl-icon-compare:before { content: '\e952'; } /* '' */
.xl-icon-leak:before { content: '\e953'; } /* '' */
.xl-icon-pdf:before { content: '\e965'; } /* '' */
.xl-icon-hourly:before { content: '\e9c4'; } /* '' */
.xl-icon-tickets:before { content: '\e9d4'; } /* '' */
.xl-icon-product:before { content: '\e9f9'; } /* '' */
.xl-icon-pipeline:before { content: '\e9fa'; } /* '' */
.xl-icon-proving:before { content: '\e9fb'; } /* '' */
.xl-icon-custody:before { content: '\e9fc'; } /* '' */
.xl-icon-meterrun:before { content: '\e9fd'; } /* '' */
.xl-icon-cavern:before { content: '\e9fe'; } /* '' */
