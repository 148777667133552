/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-calendar {
	background-color: $background-color-content;
	outline: 0;

	> .sp-calendar-navigation {
		@include flexbox-container(row);
		border-bottom: 2px solid $inner-border-color;
		height: 2em;

		> .sp-calendar-navigation-item {
			@include flexbox-item(row, 1, 0);
			@include flexbox-container(column, nowrap, center, center);
			color: $color-text;
			cursor: pointer;
		}

		> .sp-calendar-navigation-item:hover {
			color: $color-accent-brand-dark;
		}

		> .sp-calendar-navigation-item.sp-disabled,
		> .sp-calendar-navigation-item.sp-disabled:hover {
			color: $color-disabled-text;
			cursor: auto;
		}

		> .sp-calendar-navigation-item-left {
			@include flexbox-item(row, 0, 0, 2em, 2em);

			> .sp-icon {
				font-size: 1.5em;
			}
		}

		> .sp-calendar-navigation-item-up {
			@include flexbox-item(row, 1, 0);
		}

		> .sp-calendar-navigation-item-right {
			@include flexbox-item(row, 0, 0, 2em, 2em);

			> .sp-icon {
				font-size: 1.5em;
			}
		}
	}

	> .sp-calendar-grid {
		@include flexbox-container(row, wrap);
		padding: .5em;

		> .sp-calendar-grid-item {
			@include flexbox-item(row, 1, 0);
			position: relative;

			> .sp-calendar-grid-item-layer {
				@include flexbox-container(column, nowrap, center, center);
				bottom: 0;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
			}

			> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-range {
				background-color: $color-grey-60;
				bottom: 10%;
				left: 0;
				right: 0;
				top: 10%;

				&.sp-calendar-grid-item-layer-range-begin {
					@include border-radius(50% 0 0 50%);
					left: 10%;
				}

				&.sp-calendar-grid-item-layer-range-end {
					@include border-radius(0 50% 50% 0);
					right: 10%;
				}
			}

			&.sp-calendar-grid-item-placeholder {
				@include flexbox-item(row, 0, 0, calc((100% - 1px) / 4), calc((100% - 1px) / 4));

				> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-placeholder {
					@include border-radius(50%);
					background-color: $background-color-content-alternate;
					bottom: 10%;
					left: 10%;
					right: 10%;
					top: 10%;
				}
			}

		}

		> .sp-calendar-grid-item::before {
			content: "";
			display: block;
			padding-top: 100%;
		}
	}

	&.sp-calendar-century {
		> .sp-calendar-grid.sp-calendar-grid-decade,
		> .sp-calendar-grid.sp-calendar-grid-year,
		> .sp-calendar-grid.sp-calendar-grid-month {
			display: none;
		}
	}

	&.sp-calendar-decade {
		> .sp-calendar-grid.sp-calendar-grid-century,
		> .sp-calendar-grid.sp-calendar-grid-year,
		> .sp-calendar-grid.sp-calendar-grid-month {
			display: none;
		}
	}

	&.sp-calendar-year {
		> .sp-calendar-grid.sp-calendar-grid-century,
		> .sp-calendar-grid.sp-calendar-grid-decade,
		> .sp-calendar-grid.sp-calendar-grid-month {
			display: none;
		}
	}

	&.sp-calendar-month {
		> .sp-calendar-grid.sp-calendar-grid-century,
		> .sp-calendar-grid.sp-calendar-grid-decade,
		> .sp-calendar-grid.sp-calendar-grid-year {
			display: none;
		}
	}
}

.sp-calendar > .sp-calendar-grid.sp-calendar-grid-century > .sp-calendar-grid-item {
	&.sp-calendar-grid-item-decade {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 4), calc((100% - 1px) / 4));

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
			@include border-radius(50%);
			background-color: $background-color-content-alternate;
			bottom: 10%;
			color: $color-text;
			cursor: pointer;
			font-size: .875em;
			left: 10%;
			right: 10%;
			top: 10%;
		}

		&.sp-calendar-grid-item-decade-previous-century > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
			color: $color-placeholder-text;
		}

		&.sp-calendar-grid-item-decade-current-century > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
			color: $color-text;
		}

		&.sp-calendar-grid-item-decade-next-century > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
			color: $color-placeholder-text;
		}

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade.sp-disabled {
			color: $color-disabled-text;
			cursor: auto;
		}
	}

	&.sp-calendar-grid-item-decade.sp-calendar-grid-item-range > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
		background-color: transparent;
	}

	&.sp-calendar-grid-item-decade.sp-calendar-grid-item-selected > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
		background-color: $color-grey-30;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-decade.sp-calendar-grid-item-active > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade {
		background-color: $color-spirit-orange;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-decade > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-decade:hover {
		background-color: $color-accent-brand-dark;
		color: $color-selected-text;
	}
}

.sp-calendar > .sp-calendar-grid.sp-calendar-grid-decade > .sp-calendar-grid-item {
	&.sp-calendar-grid-item-year {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 4), calc((100% - 1px) / 4));

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
			@include border-radius(50%);
			background-color: $background-color-content-alternate;
			bottom: 10%;
			color: $color-text;
			cursor: pointer;
			font-size: .875em;
			left: 10%;
			right: 10%;
			top: 10%;
		}

		&.sp-calendar-grid-item-year-previous-decade > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
			color: $color-placeholder-text;
		}

		&.sp-calendar-grid-item-year-current-decade > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
			color: $color-text;
		}

		&.sp-calendar-grid-item-year-next-decade > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
			color: $color-placeholder-text;
		}

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year.sp-disabled {
			color: $color-disabled-text;
			cursor: auto;
		}
	}

	&.sp-calendar-grid-item-year.sp-calendar-grid-item-range > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
		background-color: transparent;
	}

	&.sp-calendar-grid-item-year.sp-calendar-grid-item-selected > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
		background-color: $color-grey-30;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-year.sp-calendar-grid-item-active > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year {
		background-color: $color-spirit-orange;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-year > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-year:hover {
		background-color: $color-accent-brand-dark;
		color: $color-selected-text;
	}
}

.sp-calendar > .sp-calendar-grid.sp-calendar-grid-year > .sp-calendar-grid-item {
	&.sp-calendar-grid-item-month {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 4), calc((100% - 1px) / 4));

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month {
			@include border-radius(50%);
			background-color: $background-color-content-alternate;
			bottom: 10%;
			color: $color-text;
			cursor: pointer;
			font-size: .875em;
			left: 10%;
			right: 10%;
			top: 10%;
		}
	}

	&.sp-calendar-grid-item-month.sp-calendar-grid-item-range > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month {
		background-color: transparent;
	}

	&.sp-calendar-grid-item-month.sp-calendar-grid-item-selected > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month {
		background-color: $color-grey-30;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-month.sp-calendar-grid-item-active > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month {
		background-color: $color-spirit-orange;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-month > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month:hover {
		background-color: $color-accent-brand-dark;
		color: $color-selected-text;
	}

	> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-month.sp-disabled {
		color: $color-disabled-text;
		cursor: auto;
	}

}

.sp-calendar > .sp-calendar-grid.sp-calendar-grid-month > .sp-calendar-grid-item {
	&.sp-calendar-grid-item-week-day {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 7), calc((100% - 1px) / 7));

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-week-day {
			@include border-radius(50%);
			bottom: 10%;
			color: $color-text;
			font-size: .875em;
			left: 10%;
			right: 10%;
			top: 10%;
		}
	}

	&.sp-calendar-grid-item-day {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 7), calc((100% - 1px) / 7));

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
			@include border-radius(50%);
			background-color: $background-color-content-alternate;
			bottom: 10%;
			color: $color-text;
			cursor: pointer;
			font-size: .875em;
			left: 10%;
			right: 10%;
			top: 10%;
		}

		&.sp-calendar-grid-item-day-previous-month > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
			color: $color-placeholder-text;
		}

		&.sp-calendar-grid-item-day-current-month > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
			color: $color-text;
		}

		&.sp-calendar-grid-item-day-next-month > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
			color: $color-placeholder-text;
		}

		> .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day.sp-disabled {
			color: $color-disabled-text;
			cursor: auto;
		}
	}

	&.sp-calendar-grid-item-day.sp-calendar-grid-item-range > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
		background-color: transparent;
	}

	&.sp-calendar-grid-item-day.sp-calendar-grid-item-selected > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
		background-color: $color-grey-30;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-day.sp-calendar-grid-item-active > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day {
		background-color: $color-spirit-orange;
		color: $color-selected-text;
	}

	&.sp-calendar-grid-item-day > .sp-calendar-grid-item-layer.sp-calendar-grid-item-layer-day:hover {
		background-color: $color-accent-brand-dark;
		color: $color-selected-text;
	}
}

.sp-calendar.sp-calendar-fluid {
	> .sp-calendar-grid.sp-calendar-grid-century > .sp-calendar-grid-item.sp-calendar-grid-item-decade {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 12), calc((100% - 1px) / 12));
	}

	> .sp-calendar-grid.sp-calendar-grid-decade > .sp-calendar-grid-item.sp-calendar-grid-item-year {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 12), calc((100% - 1px) / 12));
	}

	> .sp-calendar-grid.sp-calendar-grid-year > .sp-calendar-grid-item.sp-calendar-grid-item-month {
		@include flexbox-item(row, 0, 0, calc((100% - 1px) / 12), calc((100% - 1px) / 12));
	}
}
