/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-message-dialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(row, nowrap, flex-start, center, stretch);
	padding: 1em;

	> .sp-icon {
		@include flexbox-item(row, 0, 0);
		color: $color-text;
		font-size: 2em;
		padding-right: .5em;
	}

	> .sp-message-dialog-text {
		@include flexbox-item(row, 1, 0);
		@include font-t(5);
		color: $color-text;
	}

	&.sp-message-dialog-style-info > .sp-icon {
		color: $color-info;
	}

	&.sp-message-dialog-style-warning > .sp-icon {
		color: $color-warn;
	}

	&.sp-message-dialog-style-error > .sp-icon {
		color: $color-error;
	}
}
