/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-linkdialog {
	@include flexbox-item(column, 1, 1);
	@include flexbox-container(column);
	min-width: 20em;
	padding: 1em;
}

.sp-linkdialog-text {
	@include flexbox-item(column, 0, 0);
	@include font-t(5);
	color: $color-text;
}

.sp-linkdialog-text.sp-error {
	@include font-t(5);
	color: $color-error-text;
}
