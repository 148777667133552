/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-number-range-input {
	@include flexbox-container(column);

	> .sp-number-range-input-slider {
		@include flexbox-item(column, 1, 1);
		@include flexbox-container(column);

		> .sp-number-range-input-slider-range {
			@include flexbox-item(column, 1, 1);
			height: 1em;
			position: relative;

			> .sp-number-range-input-slider-range-labels {
				bottom: 0;
				height: 1em;
				left: .5em;
				position: absolute;
				right: .5em;
				top: 0;

				> .sp-number-range-input-slider-range-label {
					color: $color-grey-50;
					margin-left: -1em;
					position: absolute;
					text-align: center;
					width: 2em;
				}

				> .sp-number-range-input-slider-range-label-position {
					border-left: 1px solid $color-grey-50;
					height: .35em;
					position: absolute;
					top: 1em;
				}
			}
		}

		> .sp-slider {
			@include flexbox-item(column, 1, 1);
		}
	}

	> .sp-number-range-input-range {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row);

		> .sp-number-range-input-range-begin {
			@include flexbox-item(row, 0, 0);
			@include flexbox-container(column);

			> .sp-number-input {
				@include flexbox-item(column, 1, 0);
			}
		}

		> .sp-number-range-input-range-end {
			@include flexbox-item(row, 0, 0);
			@include flexbox-container(column);
			margin-left: .5em;

			> .sp-number-input {
				@include flexbox-item(column, 1, 0);
			}
		}
	}
}
