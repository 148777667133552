/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-check-box-group-input {
	@include flexbox-container(column);

	.sp-check-box-group-input-select-deselect-all {
		@include font-t(12);
		margin: $input-border-focus-width;

		span {
			color: $color-unimportant-text;
		}
	}

	.sp-check-box-group-input-items {
		@include flexbox-item(column, 1, 1, 10em, 10em);
		@include flexbox-container(column);
		overflow-x: auto;
	}

	&.sp-check-box-group-input-borders .sp-check-box-group-input-items {
		@include border-radius($input-border-radius);
		border: $input-border-width solid $color-grey-50;
		margin: $input-border-focus-width - $input-border-width 0;
	}

	.sp-check-box {
		@include flexbox-item(column, 0, 0);
	}

	.sp-check-box-group-input-item {
		@include flexbox-item(column, 0, 0);
		@include flexbox-container(row, nowrap, flex-start, flex-start);
		cursor: pointer;

		> .sp-check-box {
			@include flexbox-item(row, 0, 0);
		}

		> .sp-check-box-group-input-item-text {
			@include flexbox-item(row, 1, 0);
		}

		&.sp-selected {
			background: $background-color-selected;

			> .sp-check-box,
			> .sp-check-box-group-input-item-text {
				color: $color-selected-text;
			}
		}
	}

	&.sp-disabled {
		.sp-check-box {
			color: $input-color-disabled;

			&.sp-check-box-group-input-borders .sp-check-box-group-input-items {
				border: 0;
				padding: $input-border-width;
			}
		}

		.sp-check-box-group-input-item {
			cursor: default;
		}
	}

	&.sp-disabled.sp-error .sp-check-box {
		color: $input-color-error-disabled;
	}

	&.sp-error .sp-check-box {
		color: $input-color-error;
	}

	&.sp-input-diff .sp-check-box {
		color: $color-blue-light;
	}

	&.sp-input-changed .sp-check-box {
		color: $color-blue;
	}

	&.sp-check-box-group-input-borders.sp-active .sp-check-box-group-input-items {
		border-bottom-width: $input-border-focus-width;
		margin-bottom: 0;

		.sp-check-box:focus {
			@include border-radius(0);
			border-left: 0;
			border-right: 0;
			padding-left: $input-border-width;
			padding-right: $input-border-width;

			&:first-child {
				border-top: 0;
				padding-top: $input-border-width;
			}

			&:last-child {
				border-bottom: 0;
				padding-bottom: $input-border-width;
			}
		}
	}

	&.sp-empty > span {
		@include font-t(7);
		color: $input-color-disabled;
		padding: .5em;
	}
}
