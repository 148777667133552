/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-input-overlay {
	@include flexbox-container(row, nowrap, flex-end);

	&.sp-input-overlay-above-input {
		align-items: flex-end;
	}

	&.sp-input-overlay-below-input {
		align-items: flex-start;
	}

	> .sp-input-overlay-error-container {
		@include flexbox-item(row, 1, 1, 100%, 100%);
		@include flexbox-container(column);

		> .sp-input-overlay-error {
			@include flexbox-item(column, 1, 1);
			@include flexbox-container(column, nowrap, center);
			@include font-t(7);
			background-color: $color-white;
			border: 1px solid $color-error;
			color: $color-error;
			line-height: 1.3125em;
			margin: 2px 2px 2px 0;
			min-height: 2rem;
			padding: 0 .5rem;
			pointer-events: all;
		}
	}

	> .sp-input-overlay-actions-container {
		@include flexbox-item(row, 0, 0);
		@include flexbox-container(row);

		> .sp-input-overlay-action-buttons {
			@include flexbox-item(row, 0, 0);
			@include flexbox-container(row);
			background-color: $color-white;
			border: 1px solid $color-text;
			color: $color-text;
			margin: 2px 0;
			pointer-events: all;

			> .sp-button {
				@include flexbox-item(row, 0, 0, 2rem, 2rem, auto);
				height: 2rem;
			}
		}
	}
}
