/**
 * Copyright © 2018, ABB Switzerland Ltd.
 */

.sp-geographical-dashboard {
	@include flexbox-container(column);
	margin: 2em;

	> .sp-panel {
		@include flexbox-item(column, 1, 0);

		.sp-loading-indicator {
			@include flexbox-item(row, 1, 1);
		}

		.sp-geographical-dashboard-error {
			@include flexbox-item(row, 1, 1);
			@include flexbox-container(column, nowrap, center, center, center);

			> span {
				@include flexbox-item(row, 0, 0);
				@include font-t(8);
				color: $color-error;
			}
		}

		.sp-map-container {
			@include flexbox-item(column, 1, 0);
			@include flexbox-container(column);
		}

		.sp-map {
			@include flexbox-item(column, 1, 0);

			.sp-map-device-info {
				@include flexbox-container(column);
				background-color: $background-color-overall;
				color: $color-text;
				font-family: $fontfamily-normal;
				height: 170px;
				width: 325px;

				> .sp-panel {
					@include flexbox-item(column, 1, 0);

					.sp-geographical-dashboard-device-overall {
						@include flexbox-item(column, 1, 0);
						@include flexbox-container(row);
						padding: .5em 1em;

						> .sp-geographical-dashboard-device-icon {
							@include flexbox-item(row, 0, 0, 4em, 4em);
							@include flexbox-container(column, nowrap, center, center);

							> .sp-icon {
								font-size: 4em;
							}
						}

						> .sp-geographical-dashboard-device-overall-message {
							@include flexbox-item(row, 1, 1);
							@include flexbox-container(column, nowrap, center, stretch, stretch);
							padding: 0 1em;
							width: 100%;

							> span {
								width: 100%;
							}

							> span:first-child {
								@include font-t(8);
								padding-bottom: .5em;
							}

							> span:last-child {
								@include font-t(7);
							}
						}
					}

					.sp-geographical-dashboard-device-tags {
						@include flexbox-item(column, 1, 0);
						@include flexbox-container(column);
						padding: .5em 0;

						> .sp-geographical-dashboard-device-tag {
							@include flexbox-item(column, 0, 0, 1.5em, 1.5em);
							@include flexbox-container(row);

							> .sp-geographical-dashboard-device-tag-text {
								@include flexbox-item(row, 0, 0, 8em, 8em);
								@include flexbox-container(column, nowrap, center);
								padding-left: 1em;
								padding-right: 1em;

								> span {
									@include font-t(7);
									color: $color-text;
								}
							}

							> .sp-geographical-dashboard-device-tag-error {
								@include flexbox-item(row, 0, 0, 2em, 2em);
								@include flexbox-container(column, nowrap, center, center);

								> .sp-icon {
									color: $color-error;
									font-size: 1.5em;
								}
							}

							> .sp-geographical-dashboard-device-tag-value {
								@include flexbox-item(row, 0, 0, 4em, 4em);
								@include flexbox-container(column, nowrap, center);
								padding-right: 1em;
								text-align: right;

								> span {
									@include font-t(8);
									color: $color-text;
								}
							}

							> .sp-geographical-dashboard-device-tag-unit {
								@include flexbox-item(row, 0, 0, 2em, 2em);
								@include flexbox-container(column, nowrap, center);
								padding-right: 1em;

								> span {
									@include font-t(10);
									color: $color-unimportant-text;
								}
							}
						}
					}
				}
			}

			.sp-map-device-info-arrow {
				border-left: 2em solid transparent !important;
				border-right: 2em solid transparent !important;
				border-top: 2em solid $background-color-content;
				height: 0;
				margin-left: calc(50% + -2em);
				margin-right: -2em;
				position: relative;
				width: 0;

				&.arrow-outside {
					margin-bottom: -2em;
				}
			}
		}
	}
}
