/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

.sp-gridcellpercentage {
	@include flexbox-container(row, nowrap, flex-start, center, center);
	height: 100%;
	white-space: nowrap;
	width: 100%;
}

.sp-gridcellpercentage.sp-gridcellpercentage-alignright {
	@include flexbox-container(row, nowrap, flex-end, center, center);
}

.sp-gridcellpercentage.sp-gridcellpercentage-aligncenter {
	@include flexbox-container(row, nowrap, center, center, center);
}

.sp-gridcellpercentage-value {
	@include flexbox-item(row, 0, 0);
}
