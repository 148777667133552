/**
 * Copyright © 2016, ABB Switzerland Ltd.
 */

.sp-accordion {
	@include flexbox-container(column, nowrap, flex-start, stretch, stretch);

	> .sp-accordion-item-heading {
		@include flexbox-item(column, 0, 0, 2em, 2em, auto);
		@include flexbox-container(row, nowrap, flex-start, stretch, stretch);
		border-bottom: 2px solid $inner-border-color;
		border-top: 2px solid $inner-border-color;
		cursor: pointer;
		margin-bottom: 2em;

		> .sp-accordion-item-heading-toggle {
			@include flexbox-item(row, 0, 0, 2em, 2em, auto);
			@include flexbox-container(column, nowrap, center, center, stretch);

			> .sp-icon {
				color: $color-text;
				font-size: 1.5em;
			}
		}

		> .sp-accordion-item-heading-content {
			@include flexbox-item(row, 1, 0, auto, auto, auto);
		}

		&.sp-accordion-item-heading-expanded {
			margin-bottom: 0;

			> .sp-accordion-item-heading-toggle > .sp-icon {
				color: $color-active;
			}
		}
	}

	> .sp-accordion-item-content {
		@include flexbox-item(column, 0, 0);
		display: none !important;
	}
}

.sp-accordion-item-heading.sp-accordion-item-heading-expanded + .sp-accordion-item-content {
	@include flexbox-item(column, 0, 0, auto, auto, auto);
	display: flex !important;
}
