/**
 * Copyright © 2015, ABB Switzerland Ltd.
 */

// general utilities
div {
	&.sp-center {
		display: table;  // note 'block' is usually advised but 'table' also works in older IE.
		margin: 0 auto;
	}
}

// keyframe in more browsers
@mixin keyframes($animation-name) {
	@keyframes #{$animation-name} {
		@content;
	}
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
}

// animation in more browsers
@mixin animation($str) {
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-webkit-animation: #{$str};
	animation: #{$str};
}

// transition
@mixin transition($str) {
	-moz-transition: #{$str};
	-ms-transition: #{$str};
	-o-transition: #{$str};
	-webkit-transition: #{$str};
	transition: #{$str};
}

// box shadow in more browsers
@mixin box-shadow($str) {
	-moz-box-shadow: #{$str};
	-webkit-box-shadow: #{$str};
	box-shadow: #{$str};
}

@mixin border-radius($str) {
	-moz-border-radius: #{$str};
	-webkit-border-radius: #{$str};
	border-radius: #{$str};
}

/**
 * A container with scrollable items.
 *
 * enables a container element (scrollable-container()) that does not have a fixed height (percentage based or flexbox)
 * to have a child element (scrollable-item()) that potentially have scrollbars.
 */
@mixin scrollable-container {
	position: relative;
}

/**
 * An item inside a scrollable container.
 * @param overflow-x visible | hidden | scroll | auto
 * @param overflow-y visible | hidden | scroll | auto
 */
@mixin scrollable-item($overflow-x: auto, $overflow-y: auto) {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	@if $overflow-x != visible and $overflow-y != visible {
		@if $overflow-x != $overflow-y {
			overflow-x: $overflow-x;
			overflow-y: $overflow-y;
		} @else {
			overflow: $overflow-x;
		}
	}
}

@include keyframes(animate-rotate) {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

// Add this class to elements that should have a rotation animation.
.sp-rotating {
	@include animation("animate-rotate 1s infinite ease-in-out");
	overflow: hidden;
}

.sp-spinning {
	@include animation("animate-rotate 2s infinite linear");
	overflow: hidden;
}

// Workaround for IE which sometimes does not stop the animation when the class is removed (http://stackoverflow.com/a/37841081)
.sp-icon:not(.sp-rotating):not(.sp-spinning) {
	@include animation("none");
}

$z-index-1: 1; // z-index to elevate something within one control e.g. slider bar, splitter bar
$z-index-overlay: 100; // z-index to use for overlays
